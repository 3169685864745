import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../app/hooks";
import {setAuthoritiesCookieState} from "../data/login/authoritiesCookieStateSlice";

// axios instance
const instance = axios.create();

const AxiosInterceptor = ({children}) => {
    const [isSet, setIsSet] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {

        const resInterceptor = (response) => {
            if (response.request.responseURL.includes("saml2/authenticate/idp_hszg")) {
                navigate("/")
            }
            return response;
        };

        const errInterceptor = (error) => {
            if (error.response.status === 401) {
                dispatch(setAuthoritiesCookieState({authorities: undefined}));
                navigate("/")
            }
            return Promise.reject();
        };

        const interceptor = instance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );
        setIsSet(true)
        return () => instance.interceptors.response.eject(interceptor);
    }, []);

    return isSet && children
};

export default instance;
export {AxiosInterceptor};
