import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    page: 0
}

export const clientStateSlice = createSlice({
    name: "activePageState",
    initialState,
    reducers: {
        setActivePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
    },
})

export const {setActivePage} = clientStateSlice.actions;

export default clientStateSlice.reducer;