import {Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setFreeRoomSearchStateWeekDayList} from "../../../../data/room/freeRoomSearchStateSlice";
import {getCurrentWeekDay} from "../../../../util/dateCalculator";

export const WeekDaySelection = () => {
    return (
        <>
            <WeekDaySelectionInner/>
        </>
    )
}

const WeekDaySelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const weekDays = [
        {name: "Montag", value: "0"},
        {name: "Dienstag", value: "1"},
        {name: "Mittwoch", value: "2"},
        {name: "Donnerstag", value: "3"},
        {name: "Freitag", value: "4"},
        {name: "Samstag", value: "5"}
    ]
    const selectedWeekDay = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.weekDayList);
    const dispatch = useAppDispatch();

    const handleChange = (event: SelectChangeEvent<typeof selectedWeekDay>) => {
        const {
            target: {value},
        } = event;
        dispatch(setFreeRoomSearchStateWeekDayList(
            typeof value === 'string' ? value.split(',') : value,
        ));
    }

    if (mobileClient) {
        return (
            <MobileView data={weekDays} selectedWeekDay={selectedWeekDay} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={weekDays} selectedWeekDay={selectedWeekDay} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();
    const state: boolean = props.selectedWeekDay.length === 0;
    return (
        <>
            <FormControl fullWidth size="small" error={state}>
                <InputLabel id="class-select-label">{t("selectionBoxes.weekDaySelection.title")}</InputLabel>
                <Select disabled={props.data.length === 0}
                        labelId="class-select-label"
                        id="class-select"
                        value={props.selectedWeekDay}
                        label={t("selectionBoxes.weekDaySelection.title")}
                        multiple
                        onChange={props.handleChange}
                        renderValue={(selected) => selected.join(', ')}
                >
                    {
                        props.data.map((weekDay) =>
                            <MenuItem value={weekDay.value} key={weekDay.value}>
                                <Checkbox checked={props.selectedWeekDay.indexOf(weekDay.value) > -1}/>
                                <ListItemText primary={<Box sx={getTextBoxStyle(weekDay.value)}>{weekDay.name}</Box>}/>
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </>
    )
}

const MobileView = (props) => {
    const {t} = useTranslation();
    const state: boolean = props.selectedWeekDay.length === 0;
    return (
        <>
            <FormControl fullWidth size="small" error={state}>
                <InputLabel id="class-select-label">{t("selectionBoxes.weekDaySelection.mobile.title")}</InputLabel>
                <Select disabled={props.data.length === 0}
                        labelId="class-select-label"
                        id="class-select"
                        value={props.selectedWeekDay}
                        label={t("selectionBoxes.weekDaySelection.mobile.title")}
                        multiple
                        onChange={props.handleChange}
                        renderValue={(selected) => selected.join(', ')}
                >
                    {
                        props.data.map((weekDay) => (
                                <MenuItem value={weekDay.value} key={weekDay.value}>
                                    <Checkbox checked={props.selectedWeekDay.indexOf(weekDay.value) > -1}/>
                                    <ListItemText
                                        primary={<Box sx={getTextBoxStyle(weekDay.value)}>{weekDay.name}</Box>}/>
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </FormControl>
        </>
    );
}

const getTextBoxStyle = (weekDay: number) => {
    const currentWeekDay = getCurrentWeekDay();
    if (weekDay == currentWeekDay) {
        return (
            {fontWeight: "bold"}
        )
    }
    return {}
}