import {Button, Divider, List, ListItem, Popover, styled, Typography} from "@mui/material";
import {HSZG_MAIN_ACTIVE_COLOR} from "../../../config/style/colors";

export const GeneralPopover = (props) => {

    const data: string[] = props.data;

    const id = props.id;
    const open = props.open;
    const anchorEl = props.anchorEl;
    const handleClose = props.handleClose;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <List>
                <Divider/>
                {data.map((item, index) => <PopoverItem key={index} text={item}/>)}
            </List>
        </Popover>
    )
}

const PopoverItem = (props) => {
    const text: string = props.text;
    return (
        <>
            <ListItem disablePadding>
                <Typography sx={{p: 2}}>{text}</Typography>
            </ListItem>
            <Divider/>
        </>

    )
}

export const StyledPopoverButton = styled(Button)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        color: "black"
    },
    //@ts-ignore
    [theme.getColorSchemeSelector("dark")]: {
        color: "white"
    },

    textTransform: 'none',
    fontWeight: "bold",
    "&:hover": {
        color: HSZG_MAIN_ACTIVE_COLOR,
    }
}));