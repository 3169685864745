import {forEach} from "lodash";
import {TimetableActivityParent} from "../timeTableMiddleware";
import {calendarWeekStringToDateList} from "../../util/dateCalculator";
import {StaffResponse} from "../../api/timeTable";
import {RoomResponse} from "../../api/rooms";
import {ClassesResponse} from "../../api/classes";

export type CalendarEntry = {
    readonly title: string,
    readonly startDate: Date,
    readonly endDate: Date,
    readonly classes: ClassesResponse[],
    readonly location: RoomResponse[],
    readonly lecturers: StaffResponse[],
    readonly type: string,
}

const timeStringToNumbers = (time: string): number[] => {
    const result: number[] = Array(0);
    const splitText = time.split(":");
    result.push(Number(splitText[0]));
    result.push(Number(splitText[1]));
    return result;
}

const getTimedDate = (date: Date, time: string): Date => {
    const newDate = new Date(date);
    const timeArray: number[] = timeStringToNumbers(time);
    newDate.setHours(timeArray[0]);
    newDate.setMinutes(timeArray[1]);
    return newDate;
}

export const mapRowsToCalendarData = (rows: TimetableActivityParent[], years: number[], isRoomplan = false): CalendarEntry[] => {

    const events: CalendarEntry[] = new Array(0);
    forEach(rows, (row) => {
        const dates: Date[] = calendarWeekStringToDateList(row.activity.weeks, row.activity.day, years);
        forEach(dates, (date) => {

            const startTime = getTimedDate(date, row.activity.startTime);
            const endTime = getTimedDate(date, row.activity.endTime);

            const newEvent: CalendarEntry = {
                title: isRoomplan ? row.activity.name + " " + row.activity.description : row.activity.name,
                startDate: startTime,
                endDate: endTime,
                classes: row.activity.classesInfo,
                location: row.activity.roomInfo,
                lecturers: row.activity.lecturerInfo,
                type: row.activity.type,
            }
            events.push(newEvent);
        })
    });

    return events;

}