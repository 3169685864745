import {Alert, Backdrop, Box, CircularProgress, Paper} from "@mui/material";
import {SelectionBox} from "../components/selectionBox/SelectionBox";
import {RoomSelection} from "../components/selectionBox/selection/RoomSelection";
import {WeekPicker} from "../components/datePicker/WeekPicker";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {ActivitiesCalender} from "../components/calender/ActivitiesCalender";
import {ActivitiesTable} from "../components/table/ActivitiesTable";
import {useGetRoomTimeTable} from "../middleware/roomMiddleware";
import {useEffect} from "react";
import {setActivePage} from "../data/activePageSlice";
import {ROOM_PLAN_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {RoomSearchTable} from "../components/table/RoomSearchTable";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {MainAppFooter} from "../components/appBar/MainAppFooter";

const ShowData = () => {
    const roomView = useAppSelector(state => state.roomViewState.roomView);

    if (roomView) {
        return <RoomSearchTable/>
    } else {
        return <ViewData/>
    }
}

const ViewData = () => {
    const viewState = useAppSelector(state => state.viewState);
    const roomTimeTable = useGetRoomTimeTable();

    if (roomTimeTable.isLoading) {
        return (
            <Backdrop sx={{color: '#fff'}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (roomTimeTable.isError) {
        return (
            <Alert severity="error">Es konnte kein Raumplan geladen werden. Wurde vielleicht eine Auswahl nicht
                getroffen?</Alert>
        );
    }

    if (viewState.view) {
        return <ActivitiesCalender data={roomTimeTable.data} isRoomplan={true}/>
    } else {
        return <ActivitiesTable data={roomTimeTable.data} showRoomCell={false}/>
    }
}

export const Roomplan = () => {

    const dispatch = useAppDispatch();
    const mobile = useAppSelector(state => state.clientState.mobile);

    useEffect(() => {
        dispatch(setActivePage(ROOM_PLAN_PAGE_INDEX));
    })

    if (mobile) {
        return (
            <>
                <ShowData/>
                <BottomMenu showFilterButton={true} showCurrentWeekButton={false}
                            bottomBarSelectionContent={<SelectionBox showCalendarSelectButton={true}
                                                                     showCalendarViewButton={true}
                                                                     variableSelection={<RoomSelection/>}
                                                                     showOnlyExamButton={true}
                                                                     showRoomViewButton={true}
                                                                     showPdfDownloadButton={true}
                                                                     showIcsDownloadButton={true}
                                                                     showInfoButton={false}
                            />}
                />
            </>
        )
    } else {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Paper sx={{position: "sticky", top: "0px", zIndex: 500}}>
                    <MainAppBar/>
                    <SelectionBox showCalendarSelectButton={true}
                                  showCalendarViewButton={true}
                                  variableSelection={<RoomSelection/>}
                                  showOnlyExamButton={true}
                                  showRoomViewButton={true}
                                  showPdfDownloadButton={true}
                                  showIcsDownloadButton={true}
                                  showInfoButton={false}
                    />
                    <WeekPicker/>
                </Paper>
                <Box sx={{flex: 1}}>
                    <ShowData/>
                </Box>
                <MainAppFooter/>
            </ Box>
        )
    }
}