import {AppBar, Avatar, Box, Button, Grid, Toolbar} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setActivePage} from "../../data/activePageSlice";
import {HSZG_LIGHT_GRAY, HSZG_MAIN_ACTIVE_COLOR} from "../../config/style/colors";
import styled from "@emotion/styled";
import {LoginButton} from "../selectionBox/buttons/LoginButton";
import {ChangeClientButton} from "../clients/ChangeClientButton";
import {ThemeSwitch} from "../selectionBox/buttons/ThemeSwitch";
import HszgLogo from "../../assets/HZG_Signet_50_RGB.png";

type NavigationButtonsProps = {
    showProfPlan: boolean,
    showPlanner: boolean,
}

export const MainAppBar = () => {

    const authoritiesCookie = useAppSelector(state => state.authoritiesCookieState)
    const [showProfPlan, setShowProfPlan] = useState(false);
    const [showPlanner, setShowPlanner] = useState(false);

    useEffect(() => {
        if (
            authoritiesCookie.authorities !== undefined
        ) {
            if (authoritiesCookie.authorities === "ROLE_TEACHER" || authoritiesCookie.authorities === "ROLE_RESTRICTED_TEACHER" || authoritiesCookie.authorities === "ROLE_OTHER" || authoritiesCookie.authorities === "ROLE_RESTRICTED_OTHER") {
                setShowProfPlan(true);
            }
            if (authoritiesCookie.authorities === "ROLE_PLANNER") {
                setShowProfPlan(true);
                setShowPlanner(true);
            }
        } else {
            setShowProfPlan(false);
            setShowPlanner(false);
        }
    }, [authoritiesCookie]);

    return (
        <MainAppBarInner showProfPlan={showProfPlan} showPlanner={showPlanner}/>
    )

}

const StyledMainAppBar = styled(AppBar)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        backgroundColor: HSZG_LIGHT_GRAY
    }
}));

const MainAppBarInner = (props: NavigationButtonsProps) => {
    return (
        <StyledMainAppBar position="static">
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, px: 5, display: 'flex'}}>
                    <Grid container direction="row">
                        <Grid item sm={1}>
                            <Avatar variant="square" src={HszgLogo}/>
                        </Grid>
                        <Grid item sm={9}>
                            <NavigationButtons showProfPlan={props.showProfPlan} showPlanner={props.showPlanner}/>
                        </Grid>
                        <Grid item sm={2}>
                            <Grid container justifyContent="flex-end" direction="row" alignItems="center"
                                  spacing={1}>
                                <Grid item>
                                    <ChangeClientButton/>
                                </Grid>
                                <Grid item>
                                    <ThemeSwitch/>
                                </Grid>
                                <Grid item>
                                    <LoginButton/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
        </StyledMainAppBar>
    )
}

const NavigationButtons = (props: NavigationButtonsProps) => {

    const {t} = useTranslation();

    return (
        <Grid container justifyContent="flex-start" direction="row" alignItems="center">
            <Grid item md>
                <NewAppBarButton text={t("selectionBar.home")} page={0} path="/"/>
            </Grid>
            <Grid item md>
                <NewAppBarButton text={t("selectionBar.timetable")} page={1} path="/timetable"/>
            </Grid>
            <Grid item md>
                <NewAppBarButton text={t("selectionBar.examplan")} page={2} path="/examplan"/>
            </Grid>
            <Grid item md>
                <NewAppBarButton text={t("selectionBar.roomplan")} page={3} path="/roomplan"/>
            </Grid>

            {props.showProfPlan && (
                <Grid item md>
                    <NewAppBarButton text={t("selectionBar.lecturerplan")} page={4} path="/lecturerplan"/>
                </Grid>
            )}

            {/*props.showPlanner && (  //TODO: nicht benötigt, weil es keine Planner-Funktion gibt
                <Grid item md>
                    <NewAppBarButton text={t("selectionBar.planner")} page={5} path="/planner"/>
                </Grid>
            )*/}
        </Grid>
    );

}

const StyledActiveButton = styled(Button)(({theme}) => ({

    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        color: "white"
    },

    backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
    color: "white",
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
    },
    "&:active": {
        backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
    },
    "&:focus": {
        backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
    }
}));

const StyledButton = styled(Button)(({theme}) => ({

    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        color: "black"
    },

    color: "white",
    fontWeight: "bold",
    "&:hover": {
        color: HSZG_MAIN_ACTIVE_COLOR,
    }
}));

const NewAppBarButton = (props) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const activePage: number = useAppSelector(state => state.pageState.page);

    const path: string = props.path;
    const page: number = props.page;

    const handleClick = () => {
        dispatch(setActivePage(page));
        navigate(path);
    }

    if (page === activePage) {
        return (
            <StyledActiveButton>
                {props.text}
            </StyledActiveButton>
        )
    }

    return (
        <StyledButton onClick={handleClick}>
            {props.text}
        </StyledButton>
    )
}