import {callGetHomeMessage} from "../api/home";
import {useQuery} from "@tanstack/react-query";


export const useGetHomeMessage = () => {
    return useQuery<string>({
        queryKey: ["home"],
        queryFn: async () => {
            const response = await callGetHomeMessage();
            return response.data;
        }
    })
}