import {Autocomplete, AutocompleteChangeReason, OutlinedTextFieldProps, TextField} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGetAllAvailableStaff} from "../../../middleware/staffMiddleware";
import {LoadingForm} from "../common/LoadingForm";
import {ErrorForm} from "../common/ErrorForm";
import {setLecturerNameState} from "../../../data/selector/lecturerStateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {SyntheticEvent, useEffect, useMemo, useState} from "react";
import {StaffResponse} from "../../../api/timeTable";

type LecturerSelectionViewProps = {
    data: StaffResponse[],
    selectedLecturer: StaffResponse | null,
    handleChange: (event: SyntheticEvent<Element, Event>, value: StaffResponse | null, reason: AutocompleteChangeReason) => void
}

export const LecturerSelection = () => {
    return (
        <>
            <LecturerSelectionInner/>
        </>
    )
}

const LecturerSelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    const [params, setParams] = useSearchParams();
    const {isLoading, isError, data = []} = useGetAllAvailableStaff();
    const dispatch = useAppDispatch();
    const [selectedLecturerId, setSelectedLecturerId] = useState<string>("");
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedLecturerId(getSelectedLecturer(params, data));
        dispatch(setLecturerNameState(data.find((lecturer) => lecturer.id === selectedLecturerId)?.fullName ?? ""));
    }, [params, data, selectedLecturerId, dispatch]);

    const selectedLecturer = useMemo(() =>
            data.find((lecturer) => lecturer.id === selectedLecturerId) || null,
        [data, selectedLecturerId],
    );

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.lecturerSelection")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.lecturerSelection")}/>
        )
    }

    const handleChange = (event: SyntheticEvent<Element, Event>, value: StaffResponse | null, reason: AutocompleteChangeReason) => {
        setSelectedLecturerId(value?.id || "");
        setParams((prev) => {
            prev.set("staff", value?.id || "");
            return prev
        });
    }

    if (mobileClient) {
        return (
            <MobileView data={data} selectedLecturer={selectedLecturer} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={data} selectedLecturer={selectedLecturer} handleChange={handleChange}/>
        )
    }
}

const DesktopView = ({data, selectedLecturer, handleChange}: LecturerSelectionViewProps) => {
    const {t} = useTranslation();

    return (
        <Autocomplete
            id={"class-select"}
            size={"small"}
            autoHighlight={true}
            autoSelect={true}
            options={data.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option: StaffResponse) => option.name + " (" + option.fullName + ")"}
            onChange={handleChange}
            value={selectedLecturer}
            renderInput={(params: unknown) => (
                <TextField
                    {...params as OutlinedTextFieldProps}
                    label={t("selectionBoxes.lecturerSelection")}
                />
            )}
        />
    )
}

const MobileView = ({data, selectedLecturer, handleChange}: LecturerSelectionViewProps) => {
    const {t} = useTranslation();

    return (
        <Autocomplete
            id={"class-select"}
            size={"small"}
            autoHighlight={true}
            autoSelect={true}
            options={data.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option: StaffResponse) => option.name}
            onChange={handleChange}
            value={selectedLecturer}
            renderInput={(params: unknown) => (
                <TextField
                    {...params as OutlinedTextFieldProps}
                    label={t("selectionBoxes.lecturerSelection")}
                />
            )}
        />
    )
}

const getSelectedLecturer = (params, data): string => {
    if (params.has("staff")) {
        return params.get("staff")!!;
    }
    return data[0]?.id ?? "";
}