import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    examLocation: "Alle"
}

export const examLocationStateSlice = createSlice({
    name: "examLocationState",
    initialState,
    reducers: {
        setExamLocationState: (state, action: PayloadAction<string>) => {
            state.examLocation = action.payload;
        }
    }
});

export const {setExamLocationState} = examLocationStateSlice.actions;

export default examLocationStateSlice.reducer;