import {IconButton, Tooltip} from "@mui/material"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useAppDispatch} from "../../app/hooks";
import {toggleWeekPicker} from "../../data/selector/weekPickerStateSlice";
import {useTranslation} from "react-i18next";

export const DateWeekPickerButton = () => {

    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const handleClick = () => {
        dispatch(toggleWeekPicker());
    }

    return (
        <Tooltip title={t("datePicker.tooltip")}>
            <IconButton onClick={handleClick}>
                <CalendarTodayIcon/>
            </IconButton>
        </Tooltip>
    )
}