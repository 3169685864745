import {PayloadAction, createSlice} from "@reduxjs/toolkit";

const initialState = {
    mobile: false,
    forceClient: false,
}

export const clientStateSlice = createSlice({
    name: "clientState",
    initialState,
    reducers: {
        toggleMobile: (state) => {
            state.mobile = true;
        },
        toggleDesktop: (state) => {
            state.mobile = false;
        },
        setForceClient: (state, action: PayloadAction<boolean>) => {
            state.forceClient = action.payload;
        },
    },
})

export const {toggleMobile, toggleDesktop, setForceClient} = clientStateSlice.actions;

export default clientStateSlice.reducer;