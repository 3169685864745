import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {BrowserRouter} from "react-router-dom";
import './index.css'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './store';
import {CookiesProvider} from 'react-cookie';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./config/lang/i18n";
import {AxiosInterceptor} from "./api/axios";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <CookiesProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <AxiosInterceptor>
                            <App/>
                        </AxiosInterceptor>
                    </QueryClientProvider>
                </BrowserRouter>
            </Provider>
        </CookiesProvider>
    </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
