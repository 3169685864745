import {IconButton} from "@mui/material";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const ErrorForm = (props) => {
    return (
        <>
            <IconButton disabled>
                <ErrorOutlineIcon/>
            </IconButton>
        </>
    )
}