import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setFreeRoomSearchStateStartTimeFrame} from "../../../../data/room/freeRoomSearchStateSlice";
import {searchTimes} from "../../../../util/searchTimes";
import {useEffect, useState} from "react";

export const StartTimeSelection = () => {
    return (
        <>
            <StartTimeSelectionInner/>
        </>
    )
}

const StartTimeSelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const selectedStartTime = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.startTimeframe);
    const selectedEndTime = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.endTimeframe);
    const dispatch = useAppDispatch();

    const [data, setData] = useState(searchTimes);

    useEffect(() => {
        if (selectedEndTime !== "") {
            setData(searchTimes.filter((time) => time.value < selectedEndTime))
        }
    }, [selectedEndTime])

    const handleChange = (event: SelectChangeEvent<string>) => {
        dispatch(setFreeRoomSearchStateStartTimeFrame(event.target.value));
    }

    if (mobileClient) {
        return (
            <MobileView data={data} selectedClassId={selectedStartTime} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={data} selectedClassId={selectedStartTime} handleChange={handleChange}/>
        )
    }
}

const DesktopView = (props) => {
    const {t} = useTranslation();
    const state: boolean = (props.selectedClassId === undefined || props.selectedClassId === "");
    return (
        <>
            <FormControl fullWidth size="small" error={state}>
                <InputLabel id="class-select-label">{t("selectionBoxes.startTimeSelection.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedClassId} label={t("selectionBoxes.startTimeSelection.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((classes) =>
                            <MenuItem value={classes.value} key={classes.name}>
                                {classes.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    );
}

const MobileView = (props) => {
    const {t} = useTranslation();
    const state: boolean = (props.selectedClassId === undefined || props.selectedClassId === "");
    return (
        <>
            <FormControl fullWidth size="small" error={state}>
                <InputLabel id="class-select-label">{t("selectionBoxes.startTimeSelection.mobile.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedClassId} label={t("selectionBoxes.startTimeSelection.mobile.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((classes) =>
                            <MenuItem value={classes.value} key={classes.name}>
                                {classes.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    );
}