import {Box, Paper} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useEffect} from "react";
import {setActivePage} from "../data/activePageSlice";
import {PLANNER_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {MainAppFooter} from "../components/appBar/MainAppFooter";


export const Planner = () => {

    const dispatch = useAppDispatch();
    const mobile = useAppSelector(state => state.clientState.mobile);

    useEffect(() => {
        dispatch(setActivePage(PLANNER_PAGE_INDEX));
    })

    if (mobile) {
        return (
            <>
                <BottomMenu showFilterButton={false} showCurrentWeekButton={false} bottomBarSelectionContent/>
            </>
        )
    } else {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Paper sx={{position: "sticky", top: "0px", zIndex: 500}}>
                    <MainAppBar/>
                </Paper>
                <Box sx={{flex: 1}}>
                </Box>
                <MainAppFooter/>
            </ Box>
        )
    }

}