import {configureStore} from "@reduxjs/toolkit";
import viewStateReducer from "./data/viewStateSlice";
import semesterStateReducer from "./data/selector/semesterStateSlice";
import weekStateReducer from "./data/selector/weekStateSlice";
import weekPickerStateReducer from "./data/selector/weekPickerStateSlice";
import clientStateReducer from "./data/clientStateSlice";
import activePageStateReducer from "./data/activePageSlice";
import examDataStateReducer from "./data/exam/examDataStateSlice";
import examLocationStateReducer from "./data/exam/examLocationStateSlice";
import examTypeStateReducer from "./data/exam/examTypeStateSlice";
import examSearchStateReducer from "./data/exam/examSearchStateSlice";
import examClassesStateReducer from "./data/exam/examClassesStateSlice";
import authoritiesCookieStateReducer from "./data/login/authoritiesCookieStateSlice";
import roomViewStateReducer from "./data/room/roomViewStateSlice";
import freeRoomSearchStateReducer from "./data/room/freeRoomSearchStateSlice";
import classesStateReducer from "./data/selector/classesStateSlice";
import lightDarkStateReducer from "./data/themeStateSlice";
import lecturerStateReducer from "./data/selector/lecturerStateSlice";
import roomStateReducer from "./data/selector/roomStateSlice";
import showOnlyExamStateReducer from "./data/showOnlyExamStateSlice";
import {loadState} from "./browser-storage";

export const store = configureStore({
    reducer: {
        viewState: viewStateReducer,
        semesterState: semesterStateReducer,
        weekState: weekStateReducer,
        weekPickerState: weekPickerStateReducer,
        clientState: clientStateReducer,
        pageState: activePageStateReducer,
        examDataState: examDataStateReducer,
        examLocationState: examLocationStateReducer,
        examTypeState: examTypeStateReducer,
        examSearchState: examSearchStateReducer,
        examClassesState: examClassesStateReducer,
        authoritiesCookieState: authoritiesCookieStateReducer,
        roomViewState: roomViewStateReducer,
        freeRoomSearchState: freeRoomSearchStateReducer,
        classesState: classesStateReducer,
        themeState: lightDarkStateReducer,
        lecturerState: lecturerStateReducer,
        roomState: roomStateReducer,
        showOnlyExamState: showOnlyExamStateReducer,
    },
    preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;