import {Alert, Backdrop, Box, CircularProgress, Paper} from "@mui/material";
import {WeekPicker} from "../components/datePicker/WeekPicker";
import {SelectionBox} from "../components/selectionBox/SelectionBox";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {ActivitiesCalender} from "../components/calender/ActivitiesCalender";
import {ActivitiesTable} from "../components/table/ActivitiesTable";
import {ClassesSelection} from "../components/selectionBox/selection/ClassesSelection";
import {useGetTimetable} from "../middleware/activitiesMiddleware";
import {useEffect} from "react";
import {setActivePage} from "../data/activePageSlice";
import {TIMETABLE_PLAN_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {MainAppFooter} from "../components/appBar/MainAppFooter";

export const Timetable = () => {

    const dispatch = useAppDispatch();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    useEffect(() => {
        dispatch(setActivePage(TIMETABLE_PLAN_PAGE_INDEX));
    })

    if (mobileClient) {
        return <MobileView/>;
    } else {
        return <DesktopView/>;
    }

}

const ShowData = () => {
    const viewState = useAppSelector(state => state.viewState);
    const timetable = useGetTimetable();

    if (timetable.isLoading) {
        return (
            <Backdrop sx={{color: '#fff'}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (timetable.isError) {
        return (
            <Alert severity="error">Es konnte kein Stundenplan geladen werden. Wurde vielleicht eine Auswahl nicht
                getroffen?</Alert>
        );
    }

    if (viewState.view) {
        return <ActivitiesCalender data={timetable.data}/>
    } else {
        return <ActivitiesTable data={timetable.data} showClassCell={false}/>
    }
}

const DesktopView = () => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Paper sx={{position: "sticky", top: "0px", zIndex: 500}}>
                <MainAppBar/>
                <SelectionBox showCalendarSelectButton={true}
                              showCalendarViewButton={true}
                              variableSelection={<ClassesSelection/>}
                              showOnlyExamButton={true}
                              showPdfDownloadButton={true}
                              showIcsDownloadButton={true}
                              showInfoButton={false}
                />
                <WeekPicker/>
            </Paper>
            <Box sx={{flex: 1}}>
                <ShowData/>
            </Box>
            <MainAppFooter/>
        </Box>
    )
}

const MobileView = () => {
    return (
        <>
            <ShowData/>
            <BottomMenu showFilterButton
                        showCurrentWeekButton={false}
                        bottomBarSelectionContent={
                            <SelectionBox
                                showCalendarSelectButton={true}
                                showCalendarViewButton={true}
                                variableSelection={<ClassesSelection/>}
                                showOnlyExamButton={true}
                                showPdfDownloadButton={true}
                                showIcsDownloadButton={true}
                                showInfoButton={false}
                            />
                        }
            />
        </>
    )
}