import {ActivityResponse, DayResponse, TimeTableResponse} from "../../api/timeTable";
import {Activity, TimeTable} from "../activitiesMiddleware";
import _ from "lodash";

export const mapTimeTableResponseToTimeTable = (response: TimeTableResponse): TimeTable => {
    const days: DayResponse[] = response.days;
    const weekDays = _.sortedUniqBy(days, (day) => {
        return day.weekDay
    }).map(day => day.weekDay);

    return {
        days: weekDays.map((dayIndex) => {
            return {
                index: dayIndex,
                activities: activitiesOfDay(dayIndex, days)
            }
        })
    };
}

const activitiesOfDay = (dayIndex: number, dayResponse: DayResponse[]): Activity[] => {
    const weekDay = dayResponse.filter((day) => day.weekDay === dayIndex);
    const activities: ActivityResponse[] = weekDay.map((weekDay) => weekDay.activities).flat();
    const idToActivity = _.groupBy(activities, (activity) => activity.id);
    return Object.keys(idToActivity).map((id) => {
        const activities: ActivityResponse[] = idToActivity[id];
        return {
            id: id,
            name: activities[0].name,
            description: activities[0].description,
            classes: activities[0].classes.map((classes) => classes.name).join(", "),
            classesInfo: activities[0].classes,
            room: activities[0].rooms.map((room) => room.name).join(", "),
            roomInfo: activities[0].rooms,
            lecturer: activities[0].staff.map((s) => {
                return s.fullName
            }).join(", "),
            lecturerInfo: activities[0].staff,
            type: activities[0].type,
            startTime: getTimeString(activities[0].startTime),
            endTime: getTimeString(activities[0].endTime),
            weeks: activities.map((activity) => activity.week)
        }
    })
}

const getTimeString = (time: string): string => {
    const split: string[] = time.split(":");
    split.pop();
    return split.join(":");
}