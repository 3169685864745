import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    light: false,
    dark: true,
}

export const themeStateSlice = createSlice({
    name: "lightDarkState",
    initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.dark = true;
            state.light = false;
        },
        toggleLightMode: (state) => {
            state.dark = false;
            state.light = true;
        }
    }
});

export const {toggleDarkMode, toggleLightMode} = themeStateSlice.actions;

export default themeStateSlice.reducer;