import {IconButton, Tooltip} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListIcon from '@mui/icons-material/List';
import {toggleCalendar, toggleList} from "../../../data/viewStateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import {getCurrentWeek} from "../../../util/dateCalculator";
import {setWeekState} from "../../../data/selector/weekStateSlice";

export const ViewSelection = () => {
    const viewState = useAppSelector(state => state.viewState);
    if (viewState.view) {
        return (
            <>
                <ListSelectButton/>
            </>
        )
    } else {
        return (
            <>
                <CalendarSelectButton/>
            </>
        )
    }
}

const CalendarSelectButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const currentWeekState = useAppSelector(state => state.weekState.week);

    const handleClick = () => {
        const currentWeek = getCurrentWeek();
        if (currentWeekState === 0) {
            dispatch(setWeekState(currentWeek));
        }
        dispatch(toggleCalendar());
    }

    return (
        <Tooltip title={t("selectionBoxes.viewSelection.calendar")}>
            <IconButton onClick={handleClick}>
                <CalendarMonthIcon/>
            </IconButton>
        </Tooltip>
    )
}

const ListSelectButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(toggleList());
    }

    return (
        <Tooltip title={t("selectionBoxes.viewSelection.list")}>
            <IconButton onClick={handleClick}>
                <ListIcon/>
            </IconButton>
        </Tooltip>
    )
}