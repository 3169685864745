import {useGetSemesterIdFromUrl} from "./semesterMiddleware";
import {useQuery} from "@tanstack/react-query";
import {callGetZones, ZoneResponse} from "../api/zone";


export const useGetZones = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    return useQuery<ZoneResponse[]>({
        queryKey: ["zones", semesterId],
        queryFn: async () => {
            if (semesterId === "") {
                return Promise.resolve([]);
            }
            const response = await callGetZones(semesterId);
            return response.data;
        }
    })
}