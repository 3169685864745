import styled from "@emotion/styled";
import {AppBar, Box, Grid, Typography} from "@mui/material";
import {HSZG_LIGHT_GRAY} from "../../config/style/colors";
import Toolbar from "@mui/material/Toolbar";
import {useGetClassIdFromUrl} from "../../middleware/classesMiddleware";


const StyledMainAppBar = styled(AppBar)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        backgroundColor: HSZG_LIGHT_GRAY
    }
}));

export const ECampusHeader = () => {

    const {classId = ""} = useGetClassIdFromUrl();

    return (
        <StyledMainAppBar position="static">
            <Toolbar sx={{minHeight: 0}} disableGutters>
                <Box sx={{flexGrow: 1, px: 2, display: 'flex', flexWrap: "wrap"}}>
                    <Grid container sx={{justifyContent: "center", paddingTop: 0.5}}>
                        <Grid item sm sx={{width: "100%"}}>
                            <Typography variant={"h5"} color={"black"}
                                        sx={{fontWeight: "bold"}}>Matrikel: {classId.split("@")[1]}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
        </StyledMainAppBar>
    )
}