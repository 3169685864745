import {Backdrop, Card, CardContent, Grid, IconButton, Tooltip, Typography} from "@mui/material"
import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import {useAppSelector} from "../../../../app/hooks";

export const InfoView = () => {
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    if (mobileClient) {
        return <MobileView open={open} handleClose={handleClose} handleOpen={handleOpen}/>
    } else {
        return <DesktopView open={open} handleClose={handleClose} handleOpen={handleOpen}/>
    }

}

const DesktopView = (props) => {
    return (
        <>
            <Tooltip title="Hilfe">
                <IconButton onClick={props.handleOpen}>
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.open}
                onClick={props.handleClose}
            >
                <Card sx={{width: 1 / 3, height: 1 / 2, textAlign: "left", overflow: "scroll"}}>
                    <CardContent>
                        <Grid container spacing={1} sx={{width: "100%", padding: 2}}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{fontWeight: "bold"}}>Tutorial zur Raumsuche</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Um ein Ergebnis zu erhalten, muss die folgende Auswahl
                                    getroffen werden:<br/></Typography>
                                <Typography variant="body1">- Kalenderwoche (mehrere können gewählt
                                    werden)<br/></Typography>
                                <Typography variant="body1">- Wochentag (mehrere können gewählt
                                    werden)<br/></Typography>
                                <Typography variant="body1">- Startzeit<br/></Typography>
                                <Typography variant="body1">- Endzeit<br/></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Folgende Optionen können bei Bedarf geändert werden, sind aber
                                    schon beim Laden der Seite gesetzt:<br/></Typography>
                                <Typography variant="body1">- Semester<br/></Typography>
                                <Typography variant="body1">- Stantort<br/></Typography>
                                <Typography variant="body1">- Gebäude<br/></Typography>
                                <Typography variant="body1">- Kapazität<br/></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">Es werden alle Räume gezeigt, die zu der Zeit mit den
                                    entsprechenden Rahmenbedingungen verfügbar sind</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Backdrop>
        </>
    )
}

const MobileView = (props) => {
    return (
        <>
            <Tooltip title="Hilfe">
                <IconButton onClick={props.handleOpen}>
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.open}
                onClick={props.handleClose}
            >
                <Card sx={{textAlign: "left", overflow: "scroll"}}>
                    <CardContent>
                        <Grid container spacing={1} sx={{width: "100%", padding: 2}}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{fontWeight: "bold"}}>Tutorial zur Raumsuche</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Um ein Ergebnis zu erhalten, muss die folgende Auswahl
                                    getroffen werden:<br/></Typography>
                                <Typography variant="body2">- Kalenderwoche (mehrere können gewählt
                                    werden)<br/></Typography>
                                <Typography variant="body2">- Wochentag (mehrere können gewählt
                                    werden)<br/></Typography>
                                <Typography variant="body2">- Startzeit<br/></Typography>
                                <Typography variant="body2">- Endzeit<br/></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Folgende Optionen können bei Bedarf geändert werden,
                                    sind aber schon beim Laden der Seite gesetzt:<br/></Typography>
                                <Typography variant="body2">- Semester<br/></Typography>
                                <Typography variant="body2">- Standort<br/></Typography>
                                <Typography variant="body2">- Gebäude<br/></Typography>
                                <Typography variant="body2">- Kapazität<br/></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">Es werden alle Räume gezeigt, die zu der Zeit mit den
                                    entsprechenden Rahmenbedingungen verfügbar sind.</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Backdrop>
        </>
    )
}