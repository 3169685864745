import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {SideBar} from './SideBar';
import {Box} from '@mui/material';
import {ChangeClientButton} from '../ChangeClientButton';
import {MobileSelectionBox} from '../../selectionBox/mobile/MobileSelectionBox';
import {ReactNode} from 'react';
import {LoginButton} from '../../selectionBox/buttons/LoginButton';
import {CurrentWeekButton} from '../../selectionBox/buttons/CurrentWeekButton';
import {ThemeSwitch} from '../../selectionBox/buttons/ThemeSwitch';
import styled from "@emotion/styled";
import {HSZG_LIGHT_GRAY} from '../../../config/style/colors';

type BottomMenuProps = {
    bottomBarSelectionContent: ReactNode,
    showCurrentWeekButton: boolean,
    showFilterButton: boolean,
}

const StyledBottomMenu = styled(AppBar)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        backgroundColor: HSZG_LIGHT_GRAY
    }
}));

export const BottomMenu = (props: BottomMenuProps) => {
    return (
        <StyledBottomMenu position="fixed" color="primary" sx={{top: "auto", bottom: 0,}}>
            <Toolbar>
                <SideBar/>
                {props.showFilterButton && <MobileSelectionBox content={props.bottomBarSelectionContent}/>}
                <Box sx={{flexGrow: 1}}/>
                {props.showCurrentWeekButton && <CurrentWeekButton/>}
                <ThemeSwitch/>
                <ChangeClientButton/>
                <LoginButton/>
            </Toolbar>
        </StyledBottomMenu>
    )
}