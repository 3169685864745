import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useGetSemesterIdFromUrl, useGetSemesters} from "../../../middleware/semesterMiddleware";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setSemesterState} from "../../../data/selector/semesterStateSlice";

import {LoadingForm} from "../common/LoadingForm";
import {ErrorForm} from "../common/ErrorForm";
import {useEffect} from "react";

export const SemesterSelection = () => {

    const dispatch = useAppDispatch();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    const {semesterId: selectedId = "", setId} = useGetSemesterIdFromUrl();
    const {isLoading, isError, data = []} = useGetSemesters();
    const {t} = useTranslation();

    useEffect(() => {
        if (data.length > 0 && selectedId === "") {
            dispatch(setSemesterState(data[0]?.id));
            setId(data[0]?.id);
        }
    }, [dispatch, data]);

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.semesterSelection")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.semesterSelection")}/>
        )
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        setId(event.target.value);
        dispatch(setSemesterState(event.target.name));
    }

    if (mobileClient) {
        return (
            <MobileView data={data} selectedId={selectedId} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={data} selectedId={selectedId} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="semester-select-label">{t("selectionBoxes.semesterSelection")}</InputLabel>
                <Select disabled={props.data.length === 0}
                        labelId="semester-select-label"
                        id="semester-select"
                        value={props.data.some(it => it.id === props.selectedId) ? props.selectedId : ""}
                        label={t("selectionBoxes.semesterSelection")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((semester) => <MenuItem value={semester.id}
                                                               key={semester.id}>{semester.description}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    );
}

const MobileView = (props) => {
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="semester-select-label">Semester</InputLabel>
                <Select disabled={props.data.length === 0}
                        labelId="semester-select-label"
                        id="semester-select"
                        value={props.data.some(it => it.id === props.selectedId) ? props.selectedId : ""}
                        label="Semester"
                        onChange={props.handleChange}>
                    {
                        props.data.map((semester) => <MenuItem value={semester.id}
                                                               key={semester.id}>{semester.description}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    );
}