import {Outlet} from "react-router-dom";
import {useAppSelector} from "../../app/hooks"

export const ClientSelector = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    if (mobileClient) {
        return (
            <>
                <Outlet/>
            </>
        )
    } else {
        return (
            <>
                <Outlet/>
            </>
        )
    }

}