import React, {Fragment, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setActivePage} from "../../../data/activePageSlice";
import {HSZG_MAIN_COLOR} from "../../../config/style/colors";
import HszgLogo from "../../../assets/HZG_Signet_50_RGB.png";

type Anchor = 'left';

export const SideBar = () => {

    const {t} = useTranslation();
    const authoritiesCookie = useAppSelector(state => state.authoritiesCookieState)
    const [showProfPlan, setShowProfPlan] = useState(false);
    const [showPlanner, setShowPlanner] = useState(false);

    useEffect(() => {
        if (
            authoritiesCookie.authorities !== undefined
        ) {
            if (authoritiesCookie.authorities === "ROLE_TEACHER" || authoritiesCookie.authorities === "ROLE_RESTRICTED_TEACHER" || authoritiesCookie.authorities === "ROLE_OTHER" || authoritiesCookie.authorities === "ROLE_RESTRICTED_OTHER") {
                setShowProfPlan(true);
            }
            if (authoritiesCookie.authorities === "ROLE_PLANNER") {
                setShowProfPlan(true);
                setShowPlanner(true);
            }
        }
    }, [authoritiesCookie]);

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    const list = (anchor: Anchor) => (
        <Box sx={{width: 250}} role="presentation" onClick={toggleDrawer(anchor, false)}
             onKeyDown={toggleDrawer(anchor, false)}>
            <List>
                <ListItem>
                    <Avatar variant="square" src={HszgLogo} sx={{height: 70, width: 70}}/>
                </ListItem>
                <ListItem key={"pageSectionTitle"} sx={{backgroundColor: HSZG_MAIN_COLOR, mt: 5}}>
                    <ListItemText primary={t("selectionBar.mobile.pageSectionTitle")}
                                  primaryTypographyProps={{variant: "h6"}}/>
                </ListItem>
                <DrawerButton title="Home" path="/" page={0}/>
                <DrawerButton title={t("selectionBar.timetable")} path="/timetable" page={1}/>
                <DrawerButton title={t("selectionBar.examplan")} path="/examplan" page={2}/>
                <DrawerButton title={t("selectionBar.roomplan")} path="/roomplan" page={3}/>
                {showProfPlan && <DrawerButton title={t("selectionBar.lecturerplan")} path="/lecturerplan" page={4}/>}
                {/*
                showPlanner && <DrawerButton title={t("selectionBar.planner")} path="/planner" page={5}/>
                //TODO: nicht benötigt, weil es keine Planner-Funktion gibt
                */}
                <Divider/>
                <ListItem key={"ansprechpartner"} disablePadding>
                    <ListItemButton>
                        <Link
                            href="https://www.hszg.de/studium/dein-weg-durchs-studium/studienorganisation/stunden-und-raumplanung"
                            target="_blank"
                            underline={"none"}
                            color={"inherit"}
                        >
                            <ListItemText primary={t("website_information.contact_person")}/>
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"datenschutz"} disablePadding>
                    <ListItemButton>
                        <Link
                            href="https://www.hszg.de/datenschutz.html"
                            target="_blank"
                            underline={"none"}
                            color={"inherit"}
                        >
                            <ListItemText primary={t("website_information.information_security")}/>
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"impressum"} disablePadding>
                    <ListItemButton>
                        <Link
                            href="https://www.hszg.de/impressum"
                            target="_blank"
                            underline={"none"}
                            color={"inherit"}
                        >
                            <ListItemText primary={t("website_information.legal_notice")}/>
                        </Link>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            {(['left'] as const).map((anchor) => (
                <Fragment key={anchor}>
                    <IconButton onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon/>
                    </IconButton>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {list(anchor)}
                    </Drawer>
                </Fragment>
            ))}
        </>
    );
}

const DrawerButton = (props) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(props.path);
        dispatch(setActivePage(props.page))
    }

    return (
        <ListItem key={props.title} disablePadding>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={props.title}/>
            </ListItemButton>
        </ListItem>
    )
}