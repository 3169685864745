import {IconButton, Tooltip} from "@mui/material";
import TodayIcon from '@mui/icons-material/Today';
import {getCurrentWeek} from "../../../util/dateCalculator";
import {useAppDispatch} from "../../../app/hooks";
import {setWeekState} from "../../../data/selector/weekStateSlice";
import {useTranslation} from "react-i18next";

export const CurrentWeekButton = () => {
    return (
        <>
            <CurrentWeekButtonInner/>
        </>
    )
}

const CurrentWeekButtonInner = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        const currentWeek = getCurrentWeek();
        dispatch(setWeekState(currentWeek));
    }

    return (
        <Tooltip title={t("selectionBoxes.weekSelection.current")}>
            <IconButton onClick={handleClick}>
                <TodayIcon/>
            </IconButton>
        </Tooltip>
    )
}