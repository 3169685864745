import {ClassesResponse} from "./classes";
import instance from "./axios";

export interface TimeTableResponse {
    readonly days: DayResponse[]
}

export interface DayResponse {
    readonly date: string,
    readonly week: number,
    readonly weekDay: number,
    readonly activities: ActivityResponse[]
}

export interface ActivityResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly classes: ClassesResponse[],
    readonly type: string,
    readonly week: number,
    readonly weekDay: number,
    readonly startTime: string,
    readonly endTime: string,
    readonly staff: StaffResponse[],
    readonly rooms: RoomResponse[],
}

export interface StaffResponse {
    readonly id: string,
    readonly name: string,
    readonly fullName: string,
    readonly department: DepartmentResponse,
    readonly room: string,
    readonly email: string,
    readonly phone: string,
}

interface RoomResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly department: DepartmentResponse,
    readonly capacity: number,
    readonly address: AddressResponse
}

interface DepartmentResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly telefon: string,
    readonly fax: string,
    readonly street: string
}

interface AddressResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string
}

export const callGetTimeTable = (semesterId: string, classId: string) => instance.get<TimeTableResponse>("api/v1/semesters/" + semesterId + "/classes/" + classId + "/activities");

export function weekDayToName(weekDay: number): string {
    const days: string[] = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    return days[weekDay];
}

export function nameToWeekDay(name: string): number {
    const days: string[] = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    return days.indexOf(name);
}