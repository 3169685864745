import {useState} from "react";
import {Typography} from "@mui/material";
import {StaffResponse} from "../../../api/timeTable";
import {GeneralPopover, StyledPopoverButton} from "./GeneralPopover";
import {useTranslation} from "react-i18next";

interface props {
    lecturer: StaffResponse
}

export const LecturerPopover = ({lecturer}: props) => {

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const displayText: string[] = [
        t("activitiesTable.popovers.general.name") + ": " + lecturer?.fullName,
        t("activitiesTable.popovers.general.faculty") + ": " + lecturer?.department?.name,
        //t("activitiesTable.popovers.lecturerPopover.room") + ": " + lecturer?.room,
        //t("activitiesTable.popovers.lecturerPopover.eMail") + ": " + lecturer?.email,
        //t("activitiesTable.popovers.lecturerPopover.telephoneNumber") + ": " + lecturer?.phone,
    ];

    return (
        <>
            <StyledPopoverButton size="small" onClick={handleClick}>
                <Typography variant="body2">{lecturer.fullName}</Typography>
            </StyledPopoverButton>
            <GeneralPopover data={displayText} id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}/>
        </>
    );
}