import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    lecturer: "",
    lecturerName: "",
}

export const lecturerStateSlice = createSlice({
    name: "lecturerState",
    initialState,
    reducers: {
        setLecturerState: (state, action: PayloadAction<string>) => {
            state.lecturer = action.payload;
        },
        setLecturerNameState: (state, action: PayloadAction<string>) => {
            state.lecturerName = action.payload;
        }
    }
});

export const {setLecturerState, setLecturerNameState} = lecturerStateSlice.actions;

export default lecturerStateSlice.reducer;