import {IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {toggleRoomView} from "../../../../data/room/roomViewStateSlice";
import SearchIcon from '@mui/icons-material/Search';
import BackupTableIcon from '@mui/icons-material/BackupTable';

export const RoomViewSelection = () => {
    const roomView = useAppSelector(state => state.roomViewState.roomView);
    if (roomView) {
        return (
            <>
                <RoomplanSelectButton/>
            </>
        )
    } else {
        return (
            <>
                <RoomSearchSelectButton/>
            </>
        )
    }
}

const RoomSearchSelectButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(toggleRoomView());
    }

    return (
        <Tooltip title={t("selectionBoxes.roomViewSelection.search")}>
            <IconButton onClick={handleClick}>
                <SearchIcon/>
            </IconButton>
        </Tooltip>
    )
}

const RoomplanSelectButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(toggleRoomView());
    }

    return (
        <>
            <Tooltip title={t("selectionBoxes.roomViewSelection.plan")}>
                <IconButton onClick={handleClick}>
                    <BackupTableIcon/>
                </IconButton>
            </Tooltip>
        </>
    )
}