import FileSaver from "file-saver";
import instance from "./axios";

export const callGetIcsFileClassTimeTable = (semesterId: string, classId: string, className: string) => instance.get("api/v1/ics-export/semesters/" + semesterId + "/classes/" + classId, {responseType: "blob"}).then((response) => {
    const fileNameHeader = "x-suggested-filname";
    const suggestedFileName = response.headers[fileNameHeader];
    const effectiveFileName = (suggestedFileName === undefined
        ? className + ".ics"
        : suggestedFileName);
    FileSaver.saveAs(response.data, effectiveFileName);
});

export const callGetIcsFileRoomTimeTable = (semesterId: string, roomId: string, roomName: string) => instance.get("api/v1/ics-export/semesters/" + semesterId + "/rooms/" + roomId, {responseType: "blob"}).then((response) => {
    const fileNameHeader = "x-suggested-filname";
    const suggestedFileName = response.headers[fileNameHeader];
    const effectiveFileName = (suggestedFileName === undefined
        ? roomName + ".ics"
        : suggestedFileName);
    FileSaver.saveAs(response.data, effectiveFileName);
});

export const callGetIcsFileStaffTimeTable = (semesterId: string, lecturerName: string) => instance.get("api/v1/ics-export/semesters/" + semesterId + "/staff", {responseType: "blob"}).then((response) => {
    const fileNameHeader = "x-suggested-filname";
    const suggestedFileName = response.headers[fileNameHeader];
    const effectiveFileName = (suggestedFileName === undefined
        ? lecturerName + ".ics"
        : suggestedFileName);
    FileSaver.saveAs(response.data, effectiveFileName);
});