import {StyleSheet, View} from "@react-pdf/renderer";
import {RoomTableHeader} from "./RoomTableHeader";
import {RoomTableRow} from "./RoomTableRow";
import {HSZG_MAIN_COLOR} from "../../../../config/style/colors";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1.5,
        borderColor: HSZG_MAIN_COLOR,
    },
});

export const RoomPdfTable = (props) => {
    return (
        <View style={styles.tableContainer}>
            <RoomTableHeader/>
            <RoomTableRow rows={props.rows}/>
        </View>
    );
}

