const KEY = "redux";

export function loadState() {
    if (!document.documentURI.includes("@")) {
        try {
            const serializedState = localStorage.getItem(KEY);
            if (!serializedState) return undefined;
            return JSON.parse(serializedState);
        } catch (e) {
            return undefined;
        }
    }
}

export async function saveState(state: any) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(KEY, serializedState);
    } catch (e) {
        // Ignore
    }
}