import {IconButton} from "@mui/material";
import {Experimental_CssVarsProvider as CssVarsProvider, useColorScheme} from '@mui/material/styles';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {DisabledDownloadButton} from "./DisabledDownloadButton";
import {useTranslation} from "react-i18next";
import {DoNotDisturb} from "@mui/icons-material";

export const ThemeSwitch = () => {
    return (
        <CssVarsProvider>
            <ThemeInner/>
        </CssVarsProvider>
    )
}

const ThemeInner = () => {

    const {t} = useTranslation();
    const {mode, setMode} = useColorScheme();

    const selectLightMode = () => {
        setMode("light")
    }

    const selectDarkMode = () => {
        setMode("dark");
    }

    if (mode === "light") {
        return (
            <IconButton onClick={selectDarkMode}>
                <DarkModeIcon/>
            </IconButton>
        )
    }

    if (mode === "dark") {
        return (
            <IconButton onClick={selectLightMode}>
                <LightModeIcon/>
            </IconButton>
        )
    }

    return (
        <DisabledDownloadButton
            tooltip={t("selectionBoxes.downloads.notAvailable")}
            icon={DoNotDisturb}
        />
    )

}