import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {LoadingForm} from "../common/LoadingForm";
import {useState} from "react";
import {useAppDispatch} from "../../../app/hooks";

export const ExamSelection = ({text, available, data, setter}) => {
    return (
        <>
            <ExamSelectionInner text={text} available={available} data={data} setter={setter}/>
        </>
    )
}

const ExamSelectionInner = ({text, available, data, setter}) => {

    const dispatch = useAppDispatch();
    const [selectedValue, setSelectedValue] = useState("Alle");

    if (available) {
        return (
            <LoadingForm title={text}/>
        )
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        dispatch(setter(event.target.value));
        setSelectedValue(event.target.value);
    }

    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{text}</InputLabel>
                <Select disabled={data.length === 0} labelId="class-select-label" id="class-select"
                        value={selectedValue} label={text} onChange={handleChange}>
                    {
                        data.map((value) =>
                            <MenuItem value={value} key={value}>
                                {value}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}