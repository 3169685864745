import {Activity, Day, TimeTable} from "../middleware/activitiesMiddleware";

export const filterExamDataClasses = (data: TimeTable, filter: string): TimeTable => {
    if (filter === "Alle") {
        return data;
    }

    const filteredDays: Day[] = data.days.map((day: Day) => {
        const filteredActivities: Activity[] = day.activities.filter((activity: Activity) => {
            return activity.classesInfo.some((examClass) => examClass.name.toLowerCase() === filter.toLowerCase()); // Aktivität beibehalten, wenn die Klasse den Filter enthält
        });

        return {...day, activities: filteredActivities};
    });

    return {...data, days: filteredDays};
};

export const filterExamDataName = (data: TimeTable, filter: string): TimeTable => {
    if (filter === "Alle") {
        return data;
    }

    const filteredDays: Day[] = data.days.map((day: Day) => {
        const filteredActivities: Activity[] = day.activities.filter((activity: Activity) => {
            const activityName = activity.name.toLowerCase();
            const filterLowerCase = filter.toLowerCase();
            return activityName.includes(filterLowerCase); // Aktivität beibehalten, wenn der Name den Filter enthält
        });

        return {...day, activities: filteredActivities};
    });

    return {...data, days: filteredDays};
};

export const filterExamDataLocation = (data: TimeTable, filter: string): TimeTable => {
    if (filter === "Alle") {
        return data;
    }

    const filteredDays: Day[] = data.days.map((day: Day) => {
        const filteredActivities: Activity[] = day.activities.filter((activity: Activity) => {
            if (filter === "G" && activity.room[0] === "G") {
                return true; // Aktivität beibehalten, wenn der Raum mit "G" beginnt
            } else if (filter === "Z" && activity.room[0] === "Z") {
                return true; // Aktivität beibehalten, wenn der Raum mit "Z" beginnt
            } else if (filter === "Sonstige" && activity.room[0] !== "G" && activity.room[0] !== "Z") {
                return true; // Aktivität beibehalten, wenn der Raum weder mit "G" noch mit "Z" beginnt
            }
            return false; // Aktivität entfernen, wenn keine Übereinstimmung gefunden wurde
        });

        return {...day, activities: filteredActivities};
    });

    return {...data, days: filteredDays};
};

export const filterExamDataType = (data: TimeTable, filter: string): TimeTable => {
    if (filter === "Alle") {
        return data;
    }

    const filteredDays: Day[] = data.days.map((day: Day) => {
        const filteredActivities: Activity[] = day.activities.filter((activity: Activity) => {
            return activity.type === filter; // Aktivität beibehalten, wenn der Typ mit dem Filter übereinstimmt
        });

        return {...day, activities: filteredActivities};
    });

    return {...data, days: filteredDays};
};