import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    show: false
}

export const weekPickerStateSlice = createSlice({
    name: "weekPickerState",
    initialState,
    reducers: {
        toggleWeekPicker: (state) => {
            state.show = !state.show
        },
    },
});

export const {toggleWeekPicker} = weekPickerStateSlice.actions;

export default weekPickerStateSlice.reducer;