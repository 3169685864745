import {Alert, Backdrop, Box, CircularProgress, Grid, Link, Typography} from "@mui/material"
import {useGetHomeMessage} from "../middleware/utilMiddleware";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setActivePage} from "../data/activePageSlice";
import {HOME_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {MainAppFooter} from "../components/appBar/MainAppFooter";


export const Home = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const homeMessage = useGetHomeMessage();
    const mobile = useAppSelector(state => state.clientState.mobile);

    useEffect(() => {
        dispatch(setActivePage(HOME_PAGE_INDEX));
    })

    if (homeMessage.isLoading) {
        return (
            <Backdrop sx={{color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (homeMessage.isError) {
        return (
            <Alert severity="error">{t("common.loading")}</Alert>
        );
    }

    if (mobile) {
        return (
            <>
                <Box sx={{padding: 3, marginBottom: 3}}>
                    <div dangerouslySetInnerHTML={{__html: homeMessage.data[0]}}
                         style={{textAlign: "left", fontWeight: "bold", marginBottom: 15}}/>
                    <div dangerouslySetInnerHTML={{__html: homeMessage.data[1]}} style={{textAlign: "left"}}/>

                    <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                        Bei Anfragen bezüglich der Stundenplanung wenden sie sich bitte an die Stundenplaner unter:{" "}
                        <Link
                            href="https://www.hszg.de/studium/dein-weg-durchs-studium/studienorganisation/stunden-und-raumplanung"
                            target={"_blank"}>Stunden- und Raumplanung</Link>
                    </Typography>

                    <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                        Bei Verwendungsfragen verwenden Sie bitte zuerst unsere Webseite unter: {" "}
                        <Link href="https://hrz.hszg.de/netzdienste/e-mail-und-kalender/stundenplan"
                              target={"_blank"}>hrz.hszg.de</Link>
                    </Typography>

                    <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                        Bei Verbesserungs- und Supportanfragen bezüglich der Stundenplan Webseite kontaktieren Sie uns
                        bitte unter:{" "}
                        <Link href="mailto:stundenplan@hszg.de">stundenplan@hszg.de</Link>
                    </Typography>
                </Box>
                <BottomMenu showFilterButton={false} showCurrentWeekButton={false} bottomBarSelectionContent/>
            </>
        )
    } else {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <MainAppBar/>
                <Box sx={{flex: 1}}>
                    <Grid container sx={{marginTop: 5}}>
                        <Grid item xs={3}/>
                        <Grid item xs={6}>
                            <div dangerouslySetInnerHTML={{__html: homeMessage.data[0]}}
                                 style={{textAlign: "left", fontWeight: "bold", marginBottom: 15}}/>
                            <div dangerouslySetInnerHTML={{__html: homeMessage.data[1]}} style={{textAlign: "left"}}/>

                            <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                                Bei Anfragen bezüglich der Stundenplanung wenden sie sich bitte an die Stundenplaner
                                unter:{" "}
                                <Link
                                    href="https://www.hszg.de/studium/dein-weg-durchs-studium/studienorganisation/stunden-und-raumplanung"
                                    target={"_blank"}>hszg.de</Link>
                            </Typography>

                            <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                                Bei Verwendungsfragen verwenden Sie bitte zuerst unsere Webseite unter: {" "}
                                <Link href="https://hrz.hszg.de/netzdienste/e-mail-und-kalender/stundenplan"
                                      target={"_blank"}>hrz.hszg.de</Link>
                            </Typography>

                            <Typography variant="body1" style={{textAlign: "left", marginTop: 30}}>
                                Bei Verbesserungs- und Supportanfragen bezüglich der Stundenplan Webseite kontaktieren
                                Sie uns bitte unter:{" "}
                                <Link href="mailto:stundenplan@hszg.de">stundenplan@hszg.de</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}/>
                    </Grid>
                </Box>
                <MainAppFooter/>
            </Box>
        )
    }

}