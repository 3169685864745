import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showOnlyExam: false,
}

export const showOnlyExamStateSlice = createSlice({
    name: "showOnlyExamState",
    initialState,
    reducers: {
        toggleShowOnlyExamState: (state) => {
            state.showOnlyExam = !state.showOnlyExam;
        },
    }
});

export const {toggleShowOnlyExamState} = showOnlyExamStateSlice.actions;

export default showOnlyExamStateSlice.reducer;