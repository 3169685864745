import {useQuery} from "@tanstack/react-query";
import {callGetNewToken, callGetToken, callGetUserCredentials} from "../api/user";


export const useGetUserCredentials = () => {
    return useQuery<string[]>({
        queryKey: ["userCredentials"],
        queryFn: async () => {
            const response = await callGetUserCredentials();
            return response.data;
        },
        retry: (failureCount, error) => {
            // @ts-ignore
            if (error.response.status === 403) return false;
            return failureCount < 3;
        },
    })
}

export const useGetNewToken = () => {
    return useQuery<string>({
        queryKey: ["newToken"],
        queryFn: async () => {
            const response = await callGetNewToken();
            return response.data;
        }
    })
}

export const useGetToken = () => {
    return useQuery<string>({
        queryKey: ["token"],
        queryFn: async () => {
            const response = await callGetToken();
            return response.data;
        }
    })
}