import {callGetAllAvailableStaff, callGetStaffTimeTable} from "../api/staff";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";
import {useQuery} from "@tanstack/react-query";
import {StaffResponse} from "../api/timeTable";
import {useSearchParams} from "react-router-dom";
import {TimeTable} from "./activitiesMiddleware";
import {mapTimeTableResponseToTimeTable} from "./mapper/timeTableMapper";

export interface GetStaffIdFromUrlData {
    readonly exists: boolean;
    readonly staffId: string | undefined;
    readonly setId: (value: string) => void;
}

export const useGetStaffIdFromUrl = (): GetStaffIdFromUrlData => {
    const [params, setParams] = useSearchParams();
    return {
        staffId: params.get("staff") || undefined,
        exists: params.has("staff"),
        setId: (newId) => setParams((prev) => {
            prev.set("staff", newId);
            return prev;
        })
    }
}

export const useGetAllAvailableStaff = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    return useQuery<StaffResponse[]>({
        queryKey: ["staff", semesterId],
        queryFn: async () => {
            const response = await callGetAllAvailableStaff(semesterId);
            return response.data;
        }
    })
}

export const useGetStaffTimeTable = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {staffId = ""} = useGetStaffIdFromUrl();
    return useQuery<TimeTable | undefined>({
        queryKey: ["staffTimeTable", semesterId, staffId],
        queryFn: async () => {
            if (semesterId === "" || staffId === "") {
                return Promise.resolve(undefined);
            }
            const response = await callGetStaffTimeTable(semesterId, staffId);
            return mapTimeTableResponseToTimeTable(response.data);
        }
    })
}