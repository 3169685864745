import instance from "./axios";

export interface ZoneResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly department: {
        readonly id: string,
        readonly name: string,
        readonly description: string,
        readonly telefon: string,
        readonly fax: string,
        readonly street: string
    },
}

export const callGetZones = (semesterId: string) => instance.get<ZoneResponse[]>("/api/v1/semesters/" + semesterId + "/zones");