import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    examType: "Alle"
}

export const examTypeStateSlice = createSlice({
    name: "examTypeState",
    initialState,
    reducers: {
        setExamTypeState: (state, action: PayloadAction<string>) => {
            state.examType = action.payload;
        }
    }
});

export const {setExamTypeState} = examTypeStateSlice.actions;

export default examTypeStateSlice.reducer;