import {Grid} from "@mui/material"
import {DateWeekPicker} from "./DateWeekPicker"

export const WeekPicker = () => {
    return (
        <Grid container sx={{justifyContent: "center"}}>
            <Grid item md={12}>
                <DateWeekPicker/>
            </Grid>
        </Grid>
    )
}