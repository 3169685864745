import i18next from "i18next";
import {initReactI18next} from "react-i18next";

i18next.use(initReactI18next).init({
    fallbackLng: "de",
    resources: {
        de: {
            translation: {
                themeSwitch: {
                    dark: "Dark Theme",
                    light: "Light Theme",
                },
                weekList: {
                    title: "Wochenliste",
                    semester: "Semester",
                    classId: "Class ID",
                },
                datePicker: {
                    tooltip: "Kalender zur Auswahl",
                },
                selectionBar: {
                    mobile: {
                        pageSectionTitle: "Seiten",
                    },
                    home: "Startseite",
                    timetable: "Stundenplan",
                    examplan: "Prüfungsplan",
                    roomplan: "Raumplan",
                    lecturerplan: "Lehrpersonalplan",
                    planner: "Planer",
                },
                selectionBoxes: {
                    mobile: {
                        buttonTitle: "Auswahl",
                    },
                    semesterSelection: "Semester Auswahl",
                    classesSelection: {
                        title: "Matrikel Auswahl",
                        mobile: {
                            title: "Matrikel",
                        },
                    },
                    roomSelection: "Raum Auswahl",
                    lecturerSelection: "Lehrperson",
                    loginButton: "Login",
                    logoutButton: "Logout",
                    weekSelection: {
                        title: "KW Auswahl",
                        all: "Alle KW",
                        current: "Aktuelle Woche",
                        mobile: {
                            title: "KW",
                        },
                    },
                    downloads: {
                        title: "Downloads",
                        ics: {
                            title: "ICS Download",
                            download: "Download ICS",
                            dialogTitle: "Download or Copy Link",
                            dialogText: {
                                first: "Wählen Sie eine Option, um die ICS-Datei herunterzuladen oder den Abonnement-Link in Ihre Zwischenablage zu kopieren.",
                                second: "Weitere Informationen zur Aktualisierungsrate des Kalenderabonnements und empfohlene Anwendungen finden Sie unter: ",
                                third: "Bitte teilen Sie den Abonnement-Link nicht mit anderen, da er Ihre persönlichen Zugangsdaten enthält."
                            },
                            abo: "Abo-Link"
                        },
                        pdf: "PDF Download",
                        notAvailable: "nicht verfügbar"
                    },
                    viewSelection: {
                        calendar: "Kalender",
                        list: "Liste",
                    },
                    roomViewSelection: {
                        search: "Raumsuche",
                        plan: "Raumplan",
                    },
                    examSelection: {
                        examName: "Prüfungsnamen",
                        class: "Matrikel",
                        type: "Prüfungstyp",
                        location: "Standort",
                        defaultLocations: {
                            all: "Alle",
                            g: "G",
                            z: "Z",
                            else: "Sonstige",
                        },
                    },
                    clientSelection: {
                        desktopTitle: "Desktop Ansicht",
                        mobileTitle: "Mobile Ansicht",
                        disableAutoChange: "Automatischen Wechsel sperren",
                        enableAutoChange: "Automatischen Wechsel erlauben",
                    },
                    examShowSelectionButton: {
                        inactive: "Nur Prüfungen anzeigen",
                        active: "Alles anzeigen",
                    },
                    locationSelection: {
                        title: "Standort",
                        mobile: {
                            title: "Standort",
                        },
                    },
                    zoneSelection: {
                        title: "Gebäude Auswahl",
                        all: "Alle Gebäude",
                        mobile: {
                            title: "Gebäude",
                        },
                    },
                    capacitySelection: {
                        title: "Kapazität",
                        mobile: {
                            title: "Kapazität",
                        },
                    },
                    weekDaySelection: {
                        title: "Wochentag",
                        all: "Alle",
                        mobile: {
                            title: "Wochentag",
                            all: "Alle",
                        },
                    },
                    startTimeSelection: {
                        title: "Startzeit",
                        mobile: {
                            title: "Startzeit",
                        },
                    },
                    endTimeSelection: {
                        title: "Endzeit",
                        mobile: {
                            title: "Endzeit",
                        },
                    },
                },
                activitiesTable: {
                    weekTitle: "Woche",
                    dayTitle: "Tag",
                    nameTitle: "Name",
                    typeTitle: "Art",
                    classTitle: "Matrikel",
                    lecturerTitle: "Lehrpersonal",
                    startTitle: "Beginn",
                    endTitle: "Ende",
                    roomTitle: "Raum",
                    errorText: "Es konnte kein Stundenplan geladen werden. Wurde vielleicht eine Auswahl nicht getroffen?",
                    activityTitle: "Veranstaltung",
                    time: {
                        title: "Zeit",
                        aClock: "Uhr",
                        seperator: "bis",
                    },
                    popovers: {
                        general: {
                            name: "Name",
                            description: "Beschreibung",
                            faculty: "Fakultät",
                        },
                        lecturerPopover: {
                            room: "Raum",
                            eMail: "E-Mail",
                            telephoneNumber: "Telefonnummer",
                        },
                        roomPopover: {
                            capacity: "Kapazität",
                            address: "Adresse",
                        },
                    },
                },
                roomTable: {
                    roomTitle: "Name",
                    roomDescription: "Beschreibung",
                    roomCapacity: "Kapazität",
                    roomAddress: "Adresse",
                    roomAddressDescription: "Adressbeschreibung",
                    errorText: "Es konnte kein Raumplan geladen werden. Wurde vielleicht eine Auswahl nicht getroffen?",
                },
                staffTable: {
                    errorText: "Es konnte kein Lehrpersonalplan geladen werden. Wurde vielleicht eine Auswahl nicht getroffen?",
                },
                common: {
                    loading: "Laden",
                    error: "Fehler",
                },
                website_information: {
                    contact_person: "Ansprechpartner",
                    information_security: "Datenschutz",
                    legal_notice: "Impressum",
                }
            }
        }
    }
})