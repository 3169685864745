import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    roomView: false
}

export const roomViewStateSlice = createSlice({
    name: "roomViewState",
    initialState,
    reducers: {
        toggleRoomView: (state) => {
            if (state.roomView) {
                state.roomView = false;
            } else {
                state.roomView = true;
            }
        }
    }
});

export const {toggleRoomView} = roomViewStateSlice.actions;

export default roomViewStateSlice.reducer;