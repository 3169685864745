import {Alert, Backdrop, Box, CircularProgress, Paper} from "@mui/material";
import {WeekPicker} from "../components/datePicker/WeekPicker";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {ActivitiesCalender} from "../components/calender/ActivitiesCalender";
import {ActivitiesTable} from "../components/table/ActivitiesTable";
import {useGetAllExams} from "../middleware/examsMiddleware";
import {ExamSelectionBox} from "../components/selectionBox/ExamSelectionBox";
import {setExamDataState} from "../data/exam/examDataStateSlice";
import {useEffect, useMemo} from "react";
import {setActivePage} from "../data/activePageSlice";
import {EXAM_PLAN_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {
    filterExamDataClasses,
    filterExamDataLocation,
    filterExamDataName,
    filterExamDataType
} from "../util/examFilters";
import {MainAppFooter} from "../components/appBar/MainAppFooter";

const ShowData = () => {
    const viewState = useAppSelector(state => state.viewState);
    const examTypeState = useAppSelector(state => state.examTypeState).examType;
    const examLocationState = useAppSelector(state => state.examLocationState).examLocation;
    const examSearchState = useAppSelector(state => state.examSearchState).examSearch;
    const examClassesState = useAppSelector(state => state.examClassesState).examClasses;
    const exams = useGetAllExams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (exams.data !== undefined && exams.data !== null) {
            dispatch(setExamDataState(exams.data))
        }
    }, [exams.data, dispatch])

    const viewData = useMemo(() => {
        if (exams.data && typeof exams.data === "object" && "days" in exams.data) {
            return filterExamDataClasses(
                filterExamDataName(
                    filterExamDataType(
                        filterExamDataLocation(exams.data, examLocationState),
                        examTypeState
                    ),
                    examSearchState
                ),
                examClassesState
            );
        }
        return undefined;
    }, [exams.data, examTypeState, examLocationState, examSearchState, examClassesState]);

    if (exams.isLoading) {
        return (
            <Backdrop sx={{color: '#fff'}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (exams.isError) {
        return (
            <Alert severity="error">Es konnte kein Prüfungsplan geladen werden. Wurde vielleicht eine Auswahl nicht
                getroffen?</Alert>
        );
    }

    if (viewState.view) {
        return <ActivitiesCalender data={viewData}/>
    } else {
        return <ActivitiesTable data={viewData}/>
    }
}

export const Examplan = () => {
    const dispatch = useAppDispatch();
    const mobile = useAppSelector(state => state.clientState.mobile);

    useEffect(() => {
        dispatch(setActivePage(EXAM_PLAN_PAGE_INDEX));
    })

    if (mobile) {
        return (
            <>
                <ShowData/>
                <BottomMenu showFilterButton={true}
                            showCurrentWeekButton={false}
                            bottomBarSelectionContent={<ExamSelectionBox/>}
                />
            </>
        )
    } else {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Paper sx={{position: "sticky", top: "0px", zIndex: 500}}>
                    <MainAppBar/>
                    <ExamSelectionBox/>
                    <WeekPicker/>
                </Paper>
                <Box sx={{flex: 1}}>
                    <ShowData/>
                </Box>
                <MainAppFooter/>
            </ Box>
        )
    }

}