import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks"
import {setActivePage} from "../data/activePageSlice";
import {TIMETABLE_PLAN_PAGE_INDEX} from "../config/pages";
import {useGetTimetable} from "../middleware/activitiesMiddleware";
import {Alert, Backdrop, Box, CircularProgress, Paper, useColorScheme} from "@mui/material";
import {ActivitiesTable} from "../components/table/ActivitiesTable";
import {ECampusFooter} from "../components/appBar/ECampusFooter";
import {WeekPicker} from "../components/datePicker/WeekPicker";
import {ActivitiesCalender} from "../components/calender/ActivitiesCalender";
import {ECampusHeader} from "../components/appBar/ECampusHeader";
import {setClassNameState} from "../data/selector/classesStateSlice";
import {useGetClassIdFromUrl} from "../middleware/classesMiddleware";

export const ECampus = () => {
    const dispatch = useAppDispatch();
    const {mode, setMode} = useColorScheme();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const className = useAppSelector(state => state.classesState.className);
    const {classId = ""} = useGetClassIdFromUrl();

    useEffect(() => {
        dispatch(setActivePage(TIMETABLE_PLAN_PAGE_INDEX));
        setMode("light");
    });

    if (className === "") {
        dispatch(setClassNameState(classId.replace("@", "")))
    }

    if (mobileClient) {
        return (
            <MobileView/>
        )
    } else {
        return (
            <DesktopView/>
        )
    }

}

const DesktopView = () => {
    return (
        <>
            <ECampusHeader/>
            <ShowData/>
            <Paper sx={{position: "sticky", bottom: "0px", zIndex: 500}}>
                <WeekPicker/>
                <ECampusFooter/>
            </Paper>
        </>
    )
}

const MobileView = () => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <ECampusHeader/>
            <Box sx={{flex: 1}}>
                <ShowData/>
            </Box>
            <Paper sx={{position: "sticky", bottom: "0px", zIndex: 500}}>
                <WeekPicker/>
                <ECampusFooter/>
            </Paper>
        </ Box>
    )
}

const ShowData = () => {
    const viewState = useAppSelector(state => state.viewState);
    const {isLoading, isError, data} = useGetTimetable();

    if (isLoading) {
        return (
            <Backdrop sx={{color: '#fff'}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (isError) {
        return (
            <Alert severity="error">Es konnte kein Stundenplan geladen werden. Wurde vielleicht eine Auswahl nicht
                getroffen?</Alert>
        );
    }

    if (viewState.view) {
        return <ActivitiesCalender data={data}/>
    } else {
        return <ActivitiesTable data={data} showClassCell={false}/>
    }
}