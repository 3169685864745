import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    Tooltip
} from "@mui/material";
import {useGetClassIdFromUrl} from "../../middleware/classesMiddleware";
import {useGetSemesterIdFromUrl} from "../../middleware/semesterMiddleware";
import {useGetRoomIdFromUrl} from "../../middleware/roomMiddleware";
import DownloadIcon from '@mui/icons-material/Download';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {
    callGetIcsFileClassTimeTable,
    callGetIcsFileRoomTimeTable,
    callGetIcsFileStaffTimeTable
} from "../../api/icsExport";
import {AboLinkButton} from "./AboLinkButton";
import {useAppSelector} from "../../app/hooks";
import {HSZG_MAIN_COLOR} from "../../config/style/colors";

export const IcsDownloadButton = () => {
    return (
        <>
            <IcsDownloadButtonInner/>
        </>
    )
}

const IcsDownloadButtonInner = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {classId = ""} = useGetClassIdFromUrl();
    const {roomId = ""} = useGetRoomIdFromUrl();
    const className = useAppSelector(state => state.classesState.className);
    const roomName = useAppSelector(state => state.roomState.roomName);
    const lecturerName = useAppSelector(state => state.lecturerState.lecturerName);
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const downloadICS = async () => {
        if (!(classId === "" || classId === undefined)) {
            await callGetIcsFileClassTimeTable(semesterId, classId, className);
        } else if (!(roomId === "" || roomId === undefined)) {
            await callGetIcsFileRoomTimeTable(semesterId, roomId, roomName);
        } else {
            await callGetIcsFileStaffTimeTable(semesterId, lecturerName);
        }
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={t("selectionBoxes.downloads.ics.title")}>
                <IconButton onClick={handleOpen}>
                    <DownloadIcon/>
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("selectionBoxes.downloads.ics.dialogTitle")}</DialogTitle>
                <DialogContent style={{paddingBottom: 0}}>
                    <DialogContentText>
                        {t("selectionBoxes.downloads.ics.dialogText.first")}
                        <br/><br/>
                        {t("selectionBoxes.downloads.ics.dialogText.second")}
                        <Link
                            href="https://hrz.hszg.de/netzdienste/e-mail-und-kalender/stundenplan"
                            target={"_blank"}>hrz.hszg.de</Link>
                        <br/><br/>
                        {(!(classId === "" || classId === undefined) && !(roomId === "" || roomId === undefined)) ?
                            t("selectionBoxes.downloads.ics.dialogText.third")
                            :
                            <></>
                        }
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item xs={12} sm={8} style={{paddingLeft: '4px'}}>
                            <AboLinkButton setOpen={setOpen}/>
                        </Grid>
                        {!mobileClient &&
                            <Grid item xs={0.1} sm={0.1} md={0.1} lg={0.1} xl={0.1}/>
                        }
                        <Grid
                            item
                            xs
                            sm
                            container
                            justifyContent="center"
                            style={{paddingLeft: '4px'}}
                        >
                            <Grid
                                container
                                spacing={0.5}
                                style={{
                                    flexWrap: 'nowrap',
                                    border: '1px solid',
                                    borderColor: HSZG_MAIN_COLOR,
                                    borderRadius: '4px',
                                }}
                            >

                                <Button
                                    onClick={downloadICS}
                                    style={{
                                        width: '100%',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}
                                >
                                    {t("selectionBoxes.downloads.ics.download")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}
