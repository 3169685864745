import {Divider, Grid, Link, Paper} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";


export const MainAppFooter = () => {
    const {t} = useTranslation();

    return (
        <Paper sx={{bottom: "0px", zIndex: 500}}>
            <Divider/>
            <Grid container direction="row">
                <Grid item sm={3}/>
                <Grid item sm={6}>
                    <Grid container justifyContent="center" direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                            <Link
                                href="https://www.hszg.de/studium/dein-weg-durchs-studium/studienorganisation/stunden-und-raumplanung"
                                target="_blank"
                                variant="body2"
                                underline={"none"}
                                color={"inherit"}
                            >
                                {t("website_information.contact_person")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href="https://www.hszg.de/datenschutz.html"
                                target="_blank"
                                variant="body2"
                                underline={"none"}
                                color={"inherit"}
                            >
                                {t("website_information.information_security")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href="https://www.hszg.de/impressum"
                                target="_blank"
                                variant="body2"
                                underline={"none"}
                                color={"inherit"}
                            >
                                {t("website_information.legal_notice")}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={3}/>
            </Grid>
        </ Paper>
    )
}