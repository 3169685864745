import {Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {usePostFreeRoom} from "../../middleware/roomMiddleware";
import {RoomSearch} from "../../api/rooms";
import {LoadingForm} from "../selectionBox/common/LoadingForm";
import {ErrorForm} from "../selectionBox/common/ErrorForm";
import {useTranslation} from "react-i18next";

type innerProps = {
    searchData: RoomSearch
}

export const RoomSearchTable = () => {
    const freeRoomSearch: RoomSearch = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch)
    const [allDataSet, setAllDataSet] = useState(false);
    const [errorTitle, setErrorTitle] = useState("Es konnten keine Räume geladen werden. Wurde vielleicht eine Auswahl nicht getroffen?");

    useEffect(() => {
        if (
            freeRoomSearch.location !== "" &&
            freeRoomSearch.zone !== "" &&
            freeRoomSearch.capacity !== "" &&
            freeRoomSearch.weekList.length > 0 &&
            freeRoomSearch.weekDayList.length > 0 &&
            freeRoomSearch.startTimeframe !== "" &&
            freeRoomSearch.endTimeframe !== "" &&
            freeRoomSearch.startTimeframe < freeRoomSearch.endTimeframe &&
            freeRoomSearch.startTimeframe !== freeRoomSearch.endTimeframe
        ) {
            setAllDataSet(true);
        } else {
            setAllDataSet(false);
        }

        if (freeRoomSearch.startTimeframe === freeRoomSearch.endTimeframe) {
            setErrorTitle("Die Startzeit darf nicht gleich der Endzeit sein.")
        }

        if (freeRoomSearch.startTimeframe > freeRoomSearch.endTimeframe) {
            setErrorTitle("Die Startzeit darf nicht größer als die Endzeit sein.")
        }

        if (freeRoomSearch.endTimeframe === "") {
            setErrorTitle("Es wurde keine Endzeit ausgewählt.")
        }

        if (freeRoomSearch.startTimeframe === "") {
            setErrorTitle("Es wurde keine Startzeit ausgewählt.")
        }

        if (freeRoomSearch.weekDayList.length === 0) {
            setErrorTitle("Es wurde kein Wochentag ausgewählt.")
        }

        if (freeRoomSearch.weekList.length === 0) {
            setErrorTitle("Es wurde keine Kalenderwoche ausgewählt.")
        }

        if (freeRoomSearch.capacity === "") {
            setErrorTitle("Es wurde keine Kapazität ausgewählt.")
        }

        if (freeRoomSearch.zone === "") {
            setErrorTitle("Es wurde keine Adresse ausgewählt.")
        }

        if (freeRoomSearch.location === "") {
            setErrorTitle("Es wurde kein Standort ausgewählt.")
        }
    }, [
        freeRoomSearch.location,
        freeRoomSearch.zone,
        freeRoomSearch.capacity,
        freeRoomSearch.weekList,
        freeRoomSearch.weekDayList,
        freeRoomSearch.startTimeframe,
        freeRoomSearch.endTimeframe
    ])

    if (!allDataSet) {
        return <Alert severity="error">{errorTitle}</Alert>;
    }

    return <RoomTableInner searchData={freeRoomSearch}/>;
}

const RoomTableInner = ({searchData}: innerProps) => {
    const {isLoading, isError, data} = usePostFreeRoom(searchData);
    const {t} = useTranslation();

    if (isLoading) {
        return <LoadingForm/>
    }

    if (isError) {
        return <ErrorForm/>
    }

    return (
        <TableContainer id="activityTable" component={Paper}>
            <Table size="small" aria-label="activities table">
                <TableHead>
                    <TableCell>{t("roomTable.roomTitle")}</TableCell>
                    <TableCell>{t("roomTable.roomDescription")}</TableCell>
                    <TableCell>{t("roomTable.roomDescription")}</TableCell>
                    <TableCell>{t("roomTable.roomCapacity")}</TableCell>
                    <TableCell>{t("roomTable.roomAddress")}</TableCell>
                    <TableCell>{t("roomTable.roomAddressDescription")}</TableCell>
                </TableHead>
                <TableBody>
                    {
                        data?.map(room => (
                                <TableRow>
                                    <TableCell>{room.name}</TableCell>
                                    <TableCell>{room.description}</TableCell>
                                    <TableCell>{room.department?.name}</TableCell>
                                    <TableCell>{room.capacity}</TableCell>
                                    <TableCell>{room.address?.name}</TableCell>
                                    <TableCell>{room.address?.description}</TableCell>
                                </TableRow>
                            )
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
