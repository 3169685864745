import {useQuery} from "@tanstack/react-query";
import {callGetTimeTable, StaffResponse, TimeTableResponse} from "../api/timeTable";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";
import {useGetClassIdFromUrl} from "./classesMiddleware";
import {mapTimeTableResponseToTimeTable} from "./mapper/timeTableMapper";
import {RoomResponse} from "../api/rooms";
import {ClassesResponse} from "../api/classes";

export interface TimeTable {
    readonly days: Day[]
}

export interface Day {
    readonly index: number,
    readonly activities: Activity[]
}

export interface Activity {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly classes: string,
    readonly classesInfo: ClassesResponse[],
    readonly room: string
    readonly roomInfo: RoomResponse[],
    readonly lecturer: string,
    readonly lecturerInfo: StaffResponse[],
    readonly type: string,
    readonly startTime: string,
    readonly endTime: string,
    readonly weeks: number[]
}

export const useGetTimetable = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {classId = ""} = useGetClassIdFromUrl();

    return useQuery<TimeTable | undefined>({
        queryKey: ["timeTable", semesterId, classId],
        queryFn: async () => {
            if (semesterId === "" || classId === "") {
                return Promise.resolve(undefined);
            }
            const response = await callGetTimeTable(semesterId, classId);
            return mapTimeTableResponseToTimeTable(response.data);
        }
    })
}

export const useGetTimetableRaw = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {classId = ""} = useGetClassIdFromUrl();

    return useQuery<TimeTableResponse | undefined>({
        queryKey: ["timeTableRaw", semesterId, classId],
        queryFn: async () => {

            if (semesterId === "") {
                return Promise.resolve(undefined);
            }

            const response = await callGetTimeTable(semesterId, classId);
            return response.data;
        }
    })
}