import {Autocomplete, AutocompleteChangeReason, OutlinedTextFieldProps, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {useGetAllRooms} from "../../../middleware/roomMiddleware";
import {LoadingForm} from "../common/LoadingForm";
import {ErrorForm} from "../common/ErrorForm";
import {SyntheticEvent, useEffect, useMemo, useState} from "react";
import {RoomResponse} from "../../../api/rooms";
import {useAppDispatch} from "../../../app/hooks";
import {setRoomNameState} from "../../../data/selector/roomStateSlice";

export const RoomSelection = () => {
    return (
        <>
            <RoomSelectionInner/>
        </>
    )
}

const RoomSelectionInner = () => {

    const [params, setParams] = useSearchParams();
    const {isLoading, isError, data = []} = useGetAllRooms();
    const dispatch = useAppDispatch();
    const [selectedRoomId, setSelectedRoomId] = useState<string>("");
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedRoomId(getSelectedRoom(params, data));
        dispatch(setRoomNameState(data.find((room) => room.id === selectedRoomId)?.name ?? ""));
    }, [params, data, selectedRoomId, dispatch]);

    const selectedRoom = useMemo(() =>
            data.find((lecturer) => lecturer.id === selectedRoomId) || null,
        [data, selectedRoomId],
    );

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.roomSelection")}/>
        )
    }

    if (isError || data === null) {
        return (
            <ErrorForm title={t("selectionBoxes.roomSelection")}/>
        )
    }

    const handleChange = (event: SyntheticEvent<Element, Event>, value: RoomResponse | null, reason: AutocompleteChangeReason) => {
        setParams((prev) => {
            prev.set("room", value?.id || "");
            return prev
        });
    }

    return (
        <Autocomplete
            id={"class-select"}
            size={"small"}
            autoHighlight={true}
            autoSelect={true}
            options={data.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option: RoomResponse) => option.name + ": " + option.description}
            onChange={handleChange}
            value={selectedRoom}
            renderInput={(params: unknown) => (
                <TextField
                    {...params as OutlinedTextFieldProps}
                    label={t("selectionBoxes.roomSelection")}
                />
            )}
        />
    )
}

const getSelectedRoom = (params, data): string => {
    if (params.has("room")) {
        return params.get("room")!!;
    }
    return data[0]?.department?.id ?? "";
}