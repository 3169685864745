import {
    Box,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip
} from "@mui/material";
import {useTranslation} from "react-i18next";
import TodayIcon from '@mui/icons-material/Today';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useGetWeeks} from "../../../../middleware/weekSelectionMiddleware";
import {LoadingForm} from "../../common/LoadingForm";
import {ErrorForm} from "../../common/ErrorForm";
import {setFreeRoomSearchStateWeekList} from "../../../../data/room/freeRoomSearchStateSlice";
import {getCurrentWeek} from "../../../../util/dateCalculator";

export const WeekRoomSelection = () => {

    const dispatch = useAppDispatch();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const currentWeek = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.weekList);

    const {isLoading, isError, data} = useGetWeeks();
    const {t} = useTranslation();

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    const handleChange = (event: SelectChangeEvent<typeof currentWeek>) => {
        const {
            target: {value},
        } = event;
        dispatch(setFreeRoomSearchStateWeekList(
            typeof value === 'string' ? value.split(',') : value,
        ));
    }


    if (mobileClient) {
        return (
            <MobileView data={data} handleChange={handleChange} currentWeek={currentWeek}/>
        )
    } else {
        return (
            <DesktopView data={data} handleChange={handleChange} currentWeek={currentWeek}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();

    const state: boolean = props.currentWeek.length === 0;
    return (
        <FormControl fullWidth size="small" error={state}>
            <InputLabel id="week-select-label">{t("selectionBoxes.weekSelection.title")}</InputLabel>
            <Select value={props.currentWeek}
                    onChange={props.handleChange}
                    disabled={props.data?.length === 0}
                    labelId="week-select-label"
                    id="week-select"
                    multiple
                    label={t("selectionBoxes.weekSelection.title")}
                    renderValue={(selected) => selected.join(', ')}
            >
                {props.data?.map((week) => (
                    <MenuItem value={week[0]} key={week[0]} sx={getElementStyle(week[1])}>
                        <Checkbox checked={props.currentWeek.indexOf(week[0]) > -1}/>
                        <ListItemText primary={<Box sx={getTextBoxStyle(week[0])}>{week[0]}</Box>}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const MobileView = (props) => {
    const {t} = useTranslation();
    const state: boolean = props.currentWeek.length === 0;
    return (
        <FormControl fullWidth size="small" error={state}>
            <InputLabel id="week-select-label">{t("selectionBoxes.weekSelection.mobile.title")}</InputLabel>
            <Select value={props.currentWeek}
                    onChange={props.handleChange}
                    disabled={props.data?.length === 0}
                    labelId="week-select-label"
                    id="week-select"
                    multiple
                    label={t("selectionBoxes.weekSelection.mobile.title")}
                    renderValue={(selected) => selected.join(', ')}
            >
                {props.data?.map((week) => (
                    <MenuItem value={week[0]} key={week[0]} sx={getElementStyle(week[1])}>
                        <Checkbox checked={props.currentWeek.indexOf(week[0]) > -1}/>
                        <ListItemText primary={<Box sx={getTextBoxStyle(week[0])}>{week[0]}</Box>}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export const CurrentWeekRoomButton = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const handleClick = () => {
        const currentWeek = [getCurrentWeek().toString()];
        dispatch(setFreeRoomSearchStateWeekList(currentWeek));
    }

    return (
        <Tooltip title={t("selectionBoxes.weekSelection.current")}>
            <IconButton onClick={handleClick}>
                <TodayIcon/>
            </IconButton>
        </Tooltip>
    )

}

const getTextBoxStyle = (week: number) => {
    const currentWeek = getCurrentWeek();
    if (week == currentWeek) {
        return (
            {fontWeight: "bold"}
        )
    }
    return {}
}

const getElementStyle = (state: string) => {
    if (state === "true") {
        return (
            {color: "red"}
        )
    } else {
        return (
            {}
        )
    }
}