import {Alert, Backdrop, Box, CircularProgress, Paper} from "@mui/material";
import {SelectionBox} from "../components/selectionBox/SelectionBox";
import {LecturerSelection} from "../components/selectionBox/selection/LecturerSelection";
import {WeekPicker} from "../components/datePicker/WeekPicker";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {ActivitiesCalender} from "../components/calender/ActivitiesCalender";
import {useGetStaffTimeTable} from "../middleware/staffMiddleware";
import {ActivitiesTable} from "../components/table/ActivitiesTable";
import {useEffect} from "react";
import {setActivePage} from "../data/activePageSlice";
import {LECTURER_PLAN_PAGE_INDEX} from "../config/pages";
import {BottomMenu} from "../components/clients/mobile/BottomMenu";
import {MainAppBar} from "../components/appBar/MainAppBar";
import {MainAppFooter} from "../components/appBar/MainAppFooter";

const ShowData = () => {

    const dispatch = useAppDispatch();
    const viewState = useAppSelector(state => state.viewState);
    const staffTimeTable = useGetStaffTimeTable();

    useEffect(() => {
        dispatch(setActivePage(LECTURER_PLAN_PAGE_INDEX));
    })

    if (staffTimeTable.isLoading) {
        return (
            <Backdrop sx={{color: '#fff'}}
                      open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (staffTimeTable.isError) {
        return (
            <Alert severity="error">Es konnte kein Lehrpersonalplan geladen werden. Wurde vielleicht eine Auswahl nicht
                getroffen?</Alert>
        );
    }

    if (viewState.view) {
        return <ActivitiesCalender data={staffTimeTable.data}/>
    } else {
        return <ActivitiesTable data={staffTimeTable.data} showLecturerCell={false}/>
    }
}

export const Lecturerplan = () => {

    const mobile = useAppSelector(state => state.clientState.mobile);

    if (mobile) {
        return (
            <>
                <ShowData/>
                <BottomMenu showFilterButton={true}
                            showCurrentWeekButton={false}
                            bottomBarSelectionContent={
                                <SelectionBox
                                    showCalendarSelectButton={true}
                                    showCalendarViewButton={true}
                                    variableSelection={<LecturerSelection/>}
                                    showOnlyExamButton={true}
                                    showPdfDownloadButton={true}
                                    showIcsDownloadButton={true}
                                    showInfoButton={false}
                                />
                            }
                />
            </>
        )
    } else {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Paper sx={{position: "sticky", top: "0px", zIndex: 500}}>
                    <MainAppBar/>
                    <SelectionBox
                        showCalendarSelectButton={true}
                        showCalendarViewButton={true}
                        variableSelection={<LecturerSelection/>}
                        showOnlyExamButton={true}
                        showPdfDownloadButton={true}
                        showIcsDownloadButton={true}
                        showInfoButton={false}
                    />
                    <WeekPicker/>
                </Paper>
                <Box sx={{flex: 1}}>
                    <ShowData/>
                </Box>
                <MainAppFooter/>
            </ Box>
        )
    }


}