import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    examClasses: "Alle"
}

export const examClassesStateSlice = createSlice({
    name: "examClassesState",
    initialState,
    reducers: {
        setExamClassesState: (state, action: PayloadAction<string>) => {
            state.examClasses = action.payload;
        }
    }
});

export const {setExamClassesState} = examClassesStateSlice.actions;

export default examClassesStateSlice.reducer;