import {TimeTableResponse} from "./timeTable";
import instance from "./axios";

export interface RoomResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly department: {
        readonly id: string, //ID für den Stundenplan
        readonly name: string,
        readonly description: string,
        readonly telefon: string,
        readonly fax: string,
        readonly street: string
    },
    readonly capacity: number,
    readonly address: {
        readonly id: string,
        readonly name: string,
        readonly description: string
    }
}

export interface RoomSearch {
    location: string,
    zone: string,
    capacity: string,
    weekList: string[],
    weekDayList: string[],
    startTimeframe: string,
    endTimeframe: string,
}

export const callGetAllRooms = (semesterId: string) => instance.get<RoomResponse[]>("api/v1/semesters/" + semesterId + "/rooms");

export const callPostFreeRooms = (semesterId: string, data: RoomSearch) => instance.post<RoomResponse[]>("api/v1/semesters/" + semesterId + "/rooms/search", data);

export const callGetRoomTimeTable = (semesterId: string, roomId: string) => instance.get<TimeTableResponse>("api/v1/semesters/" + semesterId + "/rooms/" + roomId + "/activities");