import {TimeTablePage} from "../components/pdf/TimeTablePage";
import {TimetableActivityParent} from "../middleware/timeTableMiddleware";
import {PDFViewer} from "@react-pdf/renderer";


export const PdfPreview = () => {

    const rows: TimetableActivityParent[] = [
        {
            id: "1",
            activity: {
                day: "Montag",
                endTime: "12:00",
                name: "Test",
                description: "Test",
                classes: "Test",
                classesInfo: [
                    {
                        id: "1",
                        name: "Test",
                        description: "Test",
                        department: {
                            id: "1",
                            name: "Test",
                            description: "Test",
                            telefon: "Test",
                            fax: "Test",
                            street: "Test"
                        }
                    }
                ],
                room: "A 101",
                roomInfo: [
                    {
                        id: "1",
                        name: "Test",
                        description: "Test",
                        department: {
                            id: "1",
                            name: "Test",
                            description: "Test",
                            telefon: "Test",
                            fax: "Test",
                            street: "Test"
                        },
                        capacity: 100,
                        address: {
                            id: "1",
                            name: "Test",
                            description: "Test"
                        }
                    }
                ],
                startTime: "10:00",
                type: "Test",
                weeks: [1, 2, 3],
                lecturer: "Test",
                lecturerInfo: [
                    {
                        id: "1",
                        name: "Test",
                        fullName: "Test",
                        department: {
                            id: "1",
                            name: "Test",
                            description: "Test",
                            telefon: "Test",
                            fax: "Test",
                            street: "Test"
                        },
                        room: "A 101",
                        email: "Test",
                        phone: "Test"
                    }
                ]

            }
        }
    ]

    const props = {
        semester: "SS 2021",
        week: 1,
        title: "Stundenplan für Raum: A 101",
        isClass: false,
        isRoom: true,
        isStaff: false,
        isKolleg: false
    }

    return (
        <PDFViewer style={{width: "100%", height: "1500px"}}>
            <TimeTablePage
                rows={rows}
                semester={props.semester}
                week={props.week}
                title={props.title}
                isClass={props.isClass}
                isRoom={props.isRoom}
                isStaff={props.isStaff}
                isKolleg={props.isKolleg}
            />
        </PDFViewer>
    )

}