import {callGetAllExams} from "../api/exam";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";
import {useQuery} from "@tanstack/react-query";
import {mapTimeTableResponseToTimeTable} from "./mapper/timeTableMapper";
import {TimeTable} from "./activitiesMiddleware";


export const useGetAllExams = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    return useQuery<TimeTable | null>({
        queryKey: ["exams", semesterId],
        queryFn: async () => {
            if (semesterId === "") {
                return Promise.resolve(null);
            }
            const response = await callGetAllExams(semesterId);
            return mapTimeTableResponseToTimeTable(response.data);
        }
    })
}