import "./style.css";

import {
    cleanRows,
    createRows,
    filterRows,
    getRowsByWeek,
    TimetableActivityParent
} from "../../middleware/timeTableMiddleware";
import {useAppSelector} from "../../app/hooks";
import {TimeTable} from "../../middleware/activitiesMiddleware";
import {ActivityTableMobile} from "./ActivityTableMobile";
import {ActivityTableDesktop} from "./ActivityTableDesktop";
import {useEffect, useState} from "react";

interface ActivitiesTableProps {
    data: TimeTable | undefined | null,
    showClassCell?: boolean,
    showLecturerCell?: boolean,
    showRoomCell?: boolean
}

export const ActivitiesTable = ({
                                    data,
                                    showClassCell = true,
                                    showLecturerCell = true,
                                    showRoomCell = true
                                }: ActivitiesTableProps) => {

    const currentWeek: number = useAppSelector(state => state.weekState.week);
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const showOnlyExams = useAppSelector(state => state.showOnlyExamState.showOnlyExam);
    const [cleanedRows, setCleanedRows] = useState<TimetableActivityParent[]>([]);

    useEffect(() => {
        setCleanedRows(cleanRows(filterRows(createRows(data), showOnlyExams)))
    }, [data, showOnlyExams]);

    const rows = getPlanByWeek(currentWeek, cleanedRows);

    if (mobileClient) {
        return (
            <ActivityTableMobile rows={rows} showClassCell={showClassCell} showLecturerCell={showLecturerCell}
                                 showRoomCell={showRoomCell}/>
        )
    } else {
        return (
            <ActivityTableDesktop rows={rows} showClassCell={showClassCell} showLecturerCell={showLecturerCell}
                                  showRoomCell={showRoomCell}/>
        )
    }

}

const getPlanByWeek = (week: number, rows: TimetableActivityParent[]): TimetableActivityParent[] => {
    if (week === 0) {
        return rows;
    } else {
        return getRowsByWeek(week, rows);
    }
}