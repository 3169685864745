import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    semester: ""
}

export const semesterStateSlice = createSlice({
    name: "semesterState",
    initialState,
    reducers: {
        setSemesterState: (state, action: PayloadAction<string>) => {
            state.semester = action.payload;
        }
    }
});

export const {setSemesterState} = semesterStateSlice.actions;

export default semesterStateSlice.reducer;