import {IconButton, Tooltip, Typography} from "@mui/material"
import LogoutIcon from '@mui/icons-material/Logout';
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../app/hooks";
import {setAuthoritiesCookieState, setDepartmentCookieState} from "../../../data/login/authoritiesCookieStateSlice";

export const LoginButton = () => {
    return (
        <>
            <LoginButtonInner/>
        </>
    )
}

const LoginButtonInner = () => {
    const {t} = useTranslation();
    const [authoritiesCookie] = useCookies(['authorities']);
    const [departmentCookie] = useCookies(['department']);
    const origin = window.location.origin
    const [loggedIn, setLoggedIn] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setAuthoritiesCookieState(authoritiesCookie));
        dispatch(setDepartmentCookieState(departmentCookie));
        if (
            authoritiesCookie.authorities !== undefined &&
            departmentCookie.department !== undefined
        ) {
            setLoggedIn(true)
        }
    }, [authoritiesCookie, departmentCookie, dispatch])

    return (
        <>
            {loggedIn &&
                <Tooltip title={t("selectionBoxes.logoutButton")}>
                    <form
                        action={`${origin}/logout`}
                        method="get"
                    >
                        <IconButton type="submit">
                            <LogoutIcon/>
                        </IconButton>
                    </form>
                </Tooltip>
            }
            {!loggedIn &&
                <Tooltip title={t("selectionBoxes.loginButton")}>
                    <form
                        action={`${origin}/saml2/authenticate/idp_hszg`}
                        method="get"
                    >
                        <IconButton type="submit">
                            <Typography variant={"body1"}>Login</Typography>
                        </IconButton>
                    </form>
                </Tooltip>
            }
        </>
    )

}