import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {HSZG_MAIN_ACTIVE_COLOR, HSZG_MAIN_COLOR} from "../../../../config/style/colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: HSZG_MAIN_COLOR,
        backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
        borderBottomWidth: 1,
        alignItems: 'stretch',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 10,
        flexGrow: 1,
    },
    week: {
        width: '10%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        paddingBottom: 3,
        paddingTop: 3,
    },
    time: {
        width: '10%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        paddingBottom: 3,
        paddingTop: 3,
    },
    activity: {
        width: '40%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        paddingBottom: 3,
        paddingTop: 3,
    },
    course: {
        width: '20%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        paddingBottom: 3,
        paddingTop: 3,
    },
    room: {
        width: '20%',
        paddingBottom: 3,
        paddingTop: 3,
    },
});

export const StaffTableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.week}>Woche</Text>
            <Text style={styles.time}>Zeit</Text>
            <Text style={styles.activity}>Veranstaltung</Text>
            <Text style={styles.course}>Matrikel</Text>
            <Text style={styles.room}>Raum</Text>
        </View>
    );
}