import {useState} from "react";
import {Typography} from "@mui/material";
import {RoomResponse} from "../../../api/rooms";
import {GeneralPopover, StyledPopoverButton} from "./GeneralPopover";
import {useTranslation} from "react-i18next";

interface props {
    room: RoomResponse
}

export const RoomPopover = ({room}: props) => {

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const displayText: string[] = [
        t("activitiesTable.popovers.general.name") + ": " + room?.name,
        t("activitiesTable.popovers.general.description") + ": " + room?.description,
        t("activitiesTable.popovers.roomPopover.capacity") + ": " + room?.capacity,
        t("activitiesTable.popovers.roomPopover.address") + ": " + room?.address?.description,
    ];

    return (
        <>
            <StyledPopoverButton size="small" onClick={handleClick}>
                <Typography variant="body2">{room.name} </Typography>
            </StyledPopoverButton>
            <GeneralPopover data={displayText} id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}/>
        </>
    );
}