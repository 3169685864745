import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import {getCurrentYears, getDateOfISOWeek, getWeekFromDate} from "../../util/dateCalculator";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setWeekState} from "../../data/selector/weekStateSlice";
import {Typography} from "@mui/material";
import {useGetWeeks} from "../../middleware/weekSelectionMiddleware";
import {useGetSemesterById} from "../../middleware/semesterMiddleware";
import {useTranslation} from "react-i18next";
import {toggleWeekPicker} from "../../data/selector/weekPickerStateSlice";

export const DateWeekPicker = () => {

    const weeks = useGetWeeks();
    const {t} = useTranslation();

    if (weeks.isLoading) {
        return (
            <Typography>
                {t("common.loading")}
            </Typography>
        )
    }

    if (weeks.isError) {
        return (
            <Typography>
                {t("common.error")}
            </Typography>
        )
    }

    return (
        <DateWeekPickerInner weeks={weeks.data}/>
    )

}

const DateWeekPickerInner = (props) => {

    const dispatch = useAppDispatch();
    const viewState: boolean = useAppSelector(state => state.weekPickerState.show);
    const {t} = useTranslation();
    const {isLoading, isError, data} = useGetSemesterById();

    if (isLoading) {
        return (
            <Typography>
                {t("common.loading")}
            </Typography>
        )
    }

    if (isError) {
        return (
            <Typography>
                {t("common.error")}
            </Typography>
        )
    }

    const years: number[] = getCurrentYears(data.description);
    const minDate: Date = getDateOfISOWeek(props.weeks![0], years[0]);
    const maxDate: Date = getDateOfISOWeek(props.weeks![props.weeks.length - 1], years.pop());

    registerLocale("de", de);

    const handleChange = (date: Date) => {
        const week: number = getWeekFromDate(date);
        dispatch(setWeekState(week));
        dispatch(toggleWeekPicker());
    }

    return (
        <>
            {viewState && (
                <DatePicker
                    locale="de"
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date: Date) => handleChange(date)}
                    showWeekNumbers
                    inline
                />
            )}
        </>
    )

}