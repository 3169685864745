import {TextField} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setExamSearchState} from "../../../data/exam/examSearchStateSlice";


export const ExamSearch = ({text}) => {
    return (
        <>
            <ExamSearchInner text={text}/>
        </>
    )
}

const ExamSearchInner = ({text}) => {
    const searchExamSearchText = useAppSelector(state => state.examSearchState).examSearch;
    const dispatch = useAppDispatch();

    const handleOnChange = (event) => {
        dispatch(setExamSearchState(event.target.value));
    }

    return (
        <>
            <TextField
                fullWidth
                size="small"
                id="outlined-search"
                type="search"
                value={searchExamSearchText}
                onChange={handleOnChange}
                label={text}
            />
        </>
    );
}