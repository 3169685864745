import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {HSZG_MAIN_COLOR} from "../../../../config/style/colors";
import {createWeekRangesString} from "../../../../middleware/timeTableMiddleware";

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: HSZG_MAIN_COLOR,
        borderBottomWidth: 1,
        alignItems: 'stretch',
        fontStyle: 'bold',
        fontSize: 10,
    },
    week: {
        width: '10%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        textAlign: 'center',
        padding: 2,
    },
    weekDetail: {},
    time: {
        width: '10%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        textAlign: 'center',
        padding: 2,
    },
    timeDetail: {},
    activity: {
        width: '40%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        textAlign: 'center',
        padding: 2,
    },
    activityDetail: {
        textAlign: 'center',
    },
    lecturer: {
        width: '30%',
        borderRightColor: HSZG_MAIN_COLOR,
        borderRightWidth: 1,
        textAlign: 'center',
        padding: 2,
    },
    lecturerDetail: {},
    room: {
        width: '10%',
        textAlign: 'center',
        padding: 2,
    },
    roomDetail: {},
});

export const ClassTableRow = (props) => {

    const rows = props.rows.map((row, index) => {
        return (
            <View style={styles.row} key={index}>
                <View style={{...styles.week, flexDirection: 'column'}}>
                    <Text style={styles.weekDetail}>{createWeekRangesString(row.activity.weeks)}</Text>
                </View>
                <View style={{...styles.time, flexDirection: 'column'}}>
                    <Text style={styles.timeDetail}>{row.activity.startTime + " bis " + row.activity.endTime}</Text>
                </View>
                <View style={{...styles.activity, flexDirection: 'column'}}>
                    <Text style={{...styles.activityDetail, marginBottom: 2}}>{row.activity.type}</Text>
                    <Text style={styles.activityDetail}>{row.activity.name}</Text>
                </View>
                <View style={{...styles.lecturer, flexDirection: 'column'}}>
                    <Text style={styles.lecturerDetail}>{row.activity.lecturer}</Text>
                </View>
                <View style={{...styles.room, flexDirection: 'column'}}>
                    <Text style={styles.roomDetail}>{row.activity.room}</Text>
                </View>
            </View>
        );
    });

    return (
        <>{rows}</>
    )
}