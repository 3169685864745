import {IconButton} from "@mui/material";

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const LoadingForm = (props) => {
    return (
        <>
            <IconButton disabled>
                <HourglassEmptyIcon/>
            </IconButton>
        </>
    )
}