import {Box, Divider, Grid} from "@mui/material";
import {SemesterSelection} from "./selection/SemesterSelection";
import {CurrentWeekButton, WeekSelection} from "./selection/WeekSelection";
import {ViewSelection} from "./selection/ViewSelection";
import {HSZG_MAIN_COLOR} from "../../config/style/colors";
import {ReactNode} from "react";
import {DateWeekPickerButton} from "../datePicker/DateWeekPickerButton";
import {IcsDownloadButton} from "../downloads/IcsDownloadButton";
import {useAppSelector} from "../../app/hooks";
import {WeekDaySelection} from "./selection/roomSearchSelection/WeekDaySelection";
import {StartTimeSelection} from "./selection/roomSearchSelection/StartTimeSelection";
import {EndTimeSelection} from "./selection/roomSearchSelection/EndTimeSelection";
import {LocationSelection} from "./selection/roomSearchSelection/LocationSelection";
import {RoomViewSelection} from "./selection/roomSearchSelection/RoomViewSelection";
import {ROOM_PLAN_PAGE_INDEX} from "../../config/pages";
import {CurrentWeekRoomButton, WeekRoomSelection} from "./selection/roomSearchSelection/WeekRoomSelection";
import {CapacitySelection} from "./selection/roomSearchSelection/CapacitySelection";
import {useGetClassIdFromUrl} from "../../middleware/classesMiddleware";
import {useGetStaffIdFromUrl} from "../../middleware/staffMiddleware";
import {DisabledDownloadButton} from "./buttons/DisabledDownloadButton";
import {useTranslation} from "react-i18next";
import {ZoneSelection} from "./selection/roomSearchSelection/ZoneSelection";
import {PdfDownloadButton} from "../downloads/PdfDownloadButton";
import {useGetRoomIdFromUrl} from "../../middleware/roomMiddleware";
import {ShowOnlyExamSelectButton} from "./selection/ShowOnlyExamSelection";
import {InfoView} from "./selection/roomSearchSelection/InfoView";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

interface props {
    variableSelection: ReactNode,
    showCalendarSelectButton: boolean,
    showCalendarViewButton: boolean,
    showRoomViewButton?: boolean,
    showOnlyExamButton: boolean,
    showPdfDownloadButton: boolean,
    showIcsDownloadButton: boolean,
    showInfoButton: boolean,
}

type ButtonRowProps = {
    showCalendarSelectButton: boolean,
    showCalendarViewButton: boolean,
    showRoomViewButton: boolean,
    showOnlyExamButton: boolean,
    showPdfButton: boolean,
    showIcsButton: boolean,
    showInfoButton: boolean,
    roomView: boolean,
}

type freeRoomSearchProps = {
    mobile: boolean,
}

export const SelectionBox = (
    {
        variableSelection,
        showCalendarSelectButton,
        showCalendarViewButton,
        showRoomViewButton = false,
        showOnlyExamButton,
        showPdfDownloadButton,
        showIcsDownloadButton,
        showInfoButton,
    }: props
) => {

    const mobile = useAppSelector(state => state.clientState.mobile);
    const activePage = useAppSelector(state => state.pageState.page);
    const roomView = useAppSelector(state => state.roomViewState.roomView);

    if (activePage === ROOM_PLAN_PAGE_INDEX && roomView) {
        return <FreeRoomSearchRow mobile={mobile}/>;
    }

    if (mobile) {
        return (
            <Box sx={{marginTop: 2}} flexGrow={1}>
                <Grid container sx={{width: "100%", px: 5}} spacing={2}>
                    <Grid item xs={12}>
                        <SemesterSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        {variableSelection}
                    </Grid>
                    <Grid item xs={12}>
                        <WeekSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonRow showCalendarSelectButton={showCalendarSelectButton}
                                   showCalendarViewButton={showCalendarViewButton}
                                   showRoomViewButton={showRoomViewButton}
                                   showOnlyExamButton={showOnlyExamButton}
                                   showPdfButton={showPdfDownloadButton}
                                   showIcsButton={showIcsDownloadButton}
                                   showInfoButton={showInfoButton}
                                   roomView={false}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 1, backgroundColor: HSZG_MAIN_COLOR}}/>
            </Box>
        );
    } else {
        return (
            <Box sx={{marginTop: 3}} flexGrow={1}>
                <Grid container sx={{width: "100%", px: 5}} spacing={2}>
                    <Grid item md={3}>
                        <SemesterSelection/>
                    </Grid>
                    <Grid item md>
                        {variableSelection}
                    </Grid>
                    <Grid item md={2}>
                        <WeekSelection/>
                    </Grid>
                    <Grid item sm={2}>
                        <ButtonRow showCalendarSelectButton={showCalendarSelectButton}
                                   showCalendarViewButton={showCalendarViewButton}
                                   showRoomViewButton={showRoomViewButton}
                                   showOnlyExamButton={showOnlyExamButton}
                                   showPdfButton={showPdfDownloadButton}
                                   showIcsButton={showIcsDownloadButton}
                                   showInfoButton={showInfoButton}
                                   roomView={false}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 3, backgroundColor: HSZG_MAIN_COLOR}}/>
            </Box>
        );
    }

}

const ButtonRow = (props: ButtonRowProps) => {

    const {t} = useTranslation();
    const {classId = ""} = useGetClassIdFromUrl();
    const {staffId = ""} = useGetStaffIdFromUrl();
    const {roomId = ""} = useGetRoomIdFromUrl();

    const semester = useAppSelector(state => state.semesterState.semester);
    const week = useAppSelector(state => state.weekState.week);

    return (
        <Grid container sx={{justifyContent: "center"}}>
            {props.showCalendarSelectButton &&
                <Grid item sm>
                    <DateWeekPickerButton/>
                </Grid>
            }
            {(props.roomView) ? (
                <Grid item sm>
                    <CurrentWeekRoomButton/>
                </Grid>
            ) : (
                <Grid item sm>
                    <CurrentWeekButton/>
                </Grid>
            )}
            {props.showCalendarViewButton &&
                <Grid item sm>
                    <ViewSelection/>
                </Grid>
            }
            {props.showOnlyExamButton &&
                <Grid item sm>
                    <ShowOnlyExamSelectButton/>
                </Grid>
            }
            {props.showRoomViewButton &&
                <Grid item sm>
                    <RoomViewSelection/>
                </Grid>
            }
            {props.showIcsButton &&
                <>
                    {((classId !== "" && classId !== undefined) || (staffId !== "" && staffId !== undefined) || (roomId !== "" && roomId !== undefined)) ? (
                        <Grid item sm>
                            <IcsDownloadButton/>
                        </Grid>
                    ) : (
                        <Grid item lg>
                            <DisabledDownloadButton
                                tooltip={t("selectionBoxes.downloads.ics.title") + " " + t("selectionBoxes.downloads.notAvailable")}
                                icon={DownloadIcon}
                            />
                        </Grid>
                    )}
                </>
            }
            {props.showPdfButton &&
                <>
                    {(classId !== "" && classId !== undefined) && (
                        <Grid item sm>
                            {<PdfDownloadButton semester={semester} week={week} classId={classId}/>}
                        </Grid>
                    )}
                    {(roomId !== "" && roomId !== undefined) && (
                        <Grid item sm>
                            {<PdfDownloadButton semester={semester} week={week} roomId={roomId}/>}
                        </Grid>
                    )}
                    {(staffId !== "" && staffId !== undefined) && (
                        <Grid item sm>
                            {<PdfDownloadButton semester={semester} week={week} staffId={staffId}/>}
                        </Grid>
                    )}
                    {((classId === "" || classId === undefined) && (staffId === "" || staffId === undefined) && (roomId === "" || roomId === undefined)) && (
                        <Grid item sm>
                            <DisabledDownloadButton
                                tooltip={t("selectionBoxes.downloads.pdf") + " " + t("selectionBoxes.downloads.notAvailable")}
                                icon={PrintIcon}
                            />
                        </Grid>
                    )}
                </>
            }
            {props.showInfoButton &&
                <Grid item sm>
                    <InfoView/>
                </Grid>
            }
        </Grid>
    )
}

const FreeRoomSearchRow = ({mobile}: freeRoomSearchProps) => {
    if (mobile) {
        return (
            <Box sx={{marginTop: 3}} flexGrow={1}>
                <Grid container sx={{width: "100%", px: 5}} spacing={2}>
                    <Grid item xs={12}>
                        <SemesterSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <LocationSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <ZoneSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <CapacitySelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <WeekRoomSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <WeekDaySelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <StartTimeSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <EndTimeSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonRow showCalendarSelectButton={false}
                                   showCalendarViewButton={false}
                                   showRoomViewButton={true}
                                   showOnlyExamButton={false}
                                   roomView={true}
                                   showPdfButton={false}
                                   showIcsButton={false}
                                   showInfoButton={true}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 3, backgroundColor: HSZG_MAIN_COLOR}}/>
            </Box>
        );
    } else {
        return (
            <Box sx={{marginTop: 3}} flexGrow={1}>
                <Grid container sx={{width: "100%", px: 5}} spacing={2}>
                    <Grid item md={3}>
                        <SemesterSelection/>
                    </Grid>
                    <Grid item md>
                        <LocationSelection/>
                    </Grid>
                    <Grid item md>
                        <ZoneSelection/>
                    </Grid>
                    <Grid item md>
                        <CapacitySelection/>
                    </Grid>
                    <Grid item md={1}>
                        <WeekRoomSelection/>
                    </Grid>
                    <Grid item md={1}>
                        <WeekDaySelection/>
                    </Grid>
                    <Grid item sm={1}>
                        <StartTimeSelection/>
                    </Grid>
                    <Grid item sm={1}>
                        <EndTimeSelection/>
                    </Grid>
                    <Grid item sm={2}>
                        <ButtonRow showCalendarSelectButton={false}
                                   showCalendarViewButton={false}
                                   showRoomViewButton={true}
                                   showOnlyExamButton={false}
                                   roomView={true}
                                   showPdfButton={false}
                                   showIcsButton={false}
                                   showInfoButton={true}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 3, backgroundColor: HSZG_MAIN_COLOR}}/>
            </Box>
        );
    }
}