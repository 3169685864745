import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setFreeRoomSearchStateCapacity} from "../../../../data/room/freeRoomSearchStateSlice";

export const CapacitySelection = () => {
    return (
        <>
            <CapacitySelectionInner/>
        </>
    )
}

const CapacitySelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const dispatch = useAppDispatch();
    const capacities = [
        {name: "Egal", value: "0"},
        {name: "5", value: "5"},
        {name: "10", value: "10"},
        {name: "20", value: "20"},
        {name: "30", value: "30"},
        {name: "40", value: "40"},
        {name: "50", value: "50"},
        {name: "60", value: "60"},
        {name: "70", value: "70"},
        {name: "80", value: "80"},
        {name: "90", value: "90"},
        {name: "100", value: "100"},
    ];
    const selectedCapacity = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.capacity);

    const handleChange = (event: SelectChangeEvent<string>) => {
        dispatch(setFreeRoomSearchStateCapacity(event.target.value));
    }

    if (mobileClient) {
        return (
            <MobileView data={capacities} selectedCapacity={selectedCapacity} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={capacities} selectedCapacity={selectedCapacity} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.capacitySelection.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedCapacity} label={t("selectionBoxes.capacitySelection.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((capacity) =>
                            <MenuItem value={capacity.value} key={capacity.name}>
                                {capacity.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}

const MobileView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.capacitySelection.mobile.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedCapacity} label={t("selectionBoxes.capacitySelection.mobile.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((capacity) =>
                            <MenuItem value={capacity.value} key={capacity.name}>
                                {capacity.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}