import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    room: "",
    roomName: "",
}

export const roomStateSlice = createSlice({
    name: "roomState",
    initialState,
    reducers: {
        setRoomState: (state, action: PayloadAction<string>) => {
            state.room = action.payload;
        },
        setRoomNameState: (state, action: PayloadAction<string>) => {
            state.roomName = action.payload;
        }
    }
});

export const {setRoomState, setRoomNameState} = roomStateSlice.actions;

export default roomStateSlice.reducer;