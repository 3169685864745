import {callGetAllRooms, callGetRoomTimeTable, callPostFreeRooms, RoomResponse, RoomSearch} from "../api/rooms";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";
import {useQuery} from "@tanstack/react-query";
import {useSearchParams} from "react-router-dom";
import {TimeTable} from "./activitiesMiddleware";
import {mapTimeTableResponseToTimeTable} from "./mapper/timeTableMapper";

export interface GetRoomIdFromUrlData {
    readonly exists: boolean;
    readonly roomId: string | undefined;
    readonly setId: (value: string) => void;
}

export const useGetRoomIdFromUrl = (): GetRoomIdFromUrlData => {
    const [params, setParams] = useSearchParams();
    return {
        roomId: params.get("room") || undefined,
        exists: params.has("room"),
        setId: (newId) => setParams((prev) => {
            prev.set("room", newId);
            return prev;
        })
    }
}

export const useGetAllRooms = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    return useQuery<RoomResponse[]>({
        queryKey: ["rooms", semesterId],
        queryFn: async () => {
            if (semesterId === "") {
                return Promise.resolve([]);
            }
            const response = await callGetAllRooms(semesterId);
            return response.data;
        }
    })
}

export const useGetRoomTimeTable = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {roomId = ""} = useGetRoomIdFromUrl();
    return useQuery<TimeTable | undefined>({
        queryKey: ["roomTimeTable", semesterId, roomId],
        queryFn: async () => {
            if (semesterId === "" || roomId === "") {
                return Promise.resolve(undefined);
            }
            const response = await callGetRoomTimeTable(semesterId, roomId);
            return mapTimeTableResponseToTimeTable(response.data);
        }
    })
}

export const usePostFreeRoom = (data: RoomSearch) => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();

    return useQuery<RoomResponse[]>({
        queryKey: ["freeRomms", semesterId, data],
        queryFn: async () => {
            if (semesterId === "") {
                return Promise.resolve([]);
            }
            const response = await callPostFreeRooms(semesterId, data);
            return response.data;
        }
    })
}