import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    week: 0
}

export const weekStateSlice = createSlice({
    name: "weekState",
    initialState,
    reducers: {
        setWeekState: (state, action: PayloadAction<number>) => {
            state.week = action.payload;
        }
    }
});

export const {setWeekState} = weekStateSlice.actions;

export default weekStateSlice.reducer;