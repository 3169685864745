import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setFreeRoomSearchStateZone} from "../../../../data/room/freeRoomSearchStateSlice";
import {useGetZones} from "../../../../middleware/zoneMiddleware";
import {LoadingForm} from "../../common/LoadingForm";
import {ErrorForm} from "../../common/ErrorForm";
import {useEffect, useState} from "react";
import {ZoneResponse} from "../../../../api/zone";

export const ZoneSelection = () => {
    return (
        <>
            <ZoneSelectionInner/>
        </>
    )
}

const ZoneSelectionInner = () => {

    const dispatch = useAppDispatch();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const {isLoading, isError, data} = useGetZones();
    const selectedLocation = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.location);
    const selectedZone = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.zone);
    const {t} = useTranslation();
    const [viewData, setViewData] = useState<ZoneResponse[]>([]);

    useEffect(() => {
        if (data !== undefined && data !== null) {
            data.sort((a, b) => a.name.localeCompare(b.name));
            if (selectedLocation.startsWith("G")) {
                setViewData(data.filter(zone => zone.name.startsWith("G")));
            }
            if (selectedLocation.startsWith("Z")) {
                setViewData(data.filter(zone => zone.name.startsWith("Z")));
            }
            if (selectedLocation.startsWith("%")) {
                setViewData(data);
            }
        }
    }, [selectedLocation, data]);

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.zoneSelection.title")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.zoneSelection.title")}/>
        )
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        dispatch(setFreeRoomSearchStateZone(event.target.value));
    }

    if (mobileClient) {
        return (
            <MobileView data={viewData} selectedZone={selectedZone} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={viewData} selectedZone={selectedZone} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.zoneSelection.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedZone} label={t("selectionBoxes.zoneSelection.title")}
                        onChange={props.handleChange}>
                    <MenuItem value={"%"}>{t("selectionBoxes.zoneSelection.all")}</MenuItem>
                    {
                        props.data.map((zone) =>
                            <MenuItem value={zone.name} key={zone.id}>
                                {zone.name + ": " + zone.description}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}

const MobileView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.zoneSelection.mobile.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedZone} label={t("selectionBoxes.zoneSelection.mobile.title")}
                        onChange={props.handleChange}>
                    <MenuItem value={"%"}>{t("selectionBoxes.zoneSelection.all")}</MenuItem>
                    {
                        props.data.map((zone) =>
                            <MenuItem value={zone.name} key={zone.id}>
                                {zone.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}