import {useQuery} from "@tanstack/react-query";
import {useSearchParams} from "react-router-dom";
import {callGetAllClasses, callGetClassById, ClassesResponse} from "../api/classes";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";

export interface GetClassesIdFromUrlData {
    readonly exists: boolean;
    readonly classId: string | undefined;
    readonly setId: (value: string) => void;
}

export const useGetClassIdFromUrl = (): GetClassesIdFromUrlData => {
    const [params, setParams] = useSearchParams();
    return {
        classId: params.get("class") || undefined,
        exists: params.has("class"),
        setId: (newId) => setParams((prev) => {
            prev.set("class", newId);
            return prev;
        })
    }
}

export const useGetClassesOfSemester = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    return useQuery<ClassesResponse[]>({
        queryKey: ["classOfSemester", semesterId],
        queryFn: async () => {
            if (semesterId === "") {
                return Promise.resolve([]);
            }
            const response = await callGetAllClasses(semesterId);
            return response.data;
        }
    });
}

export const useGetClassById = () => {
    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {classId = ""} = useGetClassIdFromUrl();
    return useQuery<ClassesResponse | null>({
        queryKey: ["classById", semesterId, classId],
        queryFn: async () => {
            if (semesterId === "" || classId === "") {
                return Promise.resolve(null);
            }
            const response = await callGetClassById(classId, semesterId);
            return response.data;
        }
    });
}