import {AppBar, Box, Grid} from "@mui/material";
import styled from "@emotion/styled";
import {HSZG_LIGHT_GRAY} from "../../config/style/colors";
import Toolbar from "@mui/material/Toolbar";
import {DateWeekPickerButton} from "../datePicker/DateWeekPickerButton";
import {CurrentWeekButton, WeekSelection} from "../selectionBox/selection/WeekSelection";
import {ShowOnlyExamSelectButton} from "../selectionBox/selection/ShowOnlyExamSelection";
import {IcsDownloadButton} from "../downloads/IcsDownloadButton";
import {DisabledDownloadButton} from "../selectionBox/buttons/DisabledDownloadButton";
import {PdfDownloadButton} from "../downloads/PdfDownloadButton";
import {ViewSelection} from "../selectionBox/selection/ViewSelection";
import {useTranslation} from "react-i18next";
import {useGetClassIdFromUrl} from "../../middleware/classesMiddleware";
import {useGetStaffIdFromUrl} from "../../middleware/staffMiddleware";
import {useGetRoomIdFromUrl} from "../../middleware/roomMiddleware";
import {useAppSelector} from "../../app/hooks";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const StyledMainAppBar = styled(AppBar)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        backgroundColor: HSZG_LIGHT_GRAY
    }
}));

export const ECampusFooter = () => {
    const {t} = useTranslation();
    const {classId = ""} = useGetClassIdFromUrl();
    const {staffId = ""} = useGetStaffIdFromUrl();
    const {roomId = ""} = useGetRoomIdFromUrl();

    const semester = useAppSelector(state => state.semesterState.semester);
    const week = useAppSelector(state => state.weekState.week);

    return (
        <StyledMainAppBar position="static" sx={{paddingBottom: "10px"}}>
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, px: 5, display: 'flex', flexWrap: "wrap"}}>
                    <Grid container sx={{justifyContent: "center", paddingTop: 1.5}}>
                        <Grid item sm sx={{width: "100%"}}>
                            <WeekSelection/>
                        </Grid>
                    </Grid>
                    <Grid container sx={{justifyContent: "center"}}>
                        <Grid item sm>
                            <DateWeekPickerButton/>
                        </Grid>
                        <Grid item sm>
                            <CurrentWeekButton/>
                        </Grid>
                        <Grid item sm>
                            <ViewSelection/>
                        </Grid>
                        <Grid item sm>
                            <ShowOnlyExamSelectButton/>
                        </Grid>
                        <>
                            {((classId !== "" && classId !== undefined) || (staffId !== "" && staffId !== undefined) || (roomId !== "" && roomId !== undefined)) ? (
                                <Grid item sm>
                                    <IcsDownloadButton/>
                                </Grid>
                            ) : (
                                <Grid item lg>
                                    <DisabledDownloadButton
                                        tooltip={t("selectionBoxes.downloads.ics.title") + " " + t("selectionBoxes.downloads.notAvailable")}
                                        icon={DownloadIcon}
                                    />
                                </Grid>
                            )}
                        </>
                        <>
                            {(classId !== "" && classId !== undefined) && (
                                <Grid item sm>
                                    {<PdfDownloadButton semester={semester} week={week} classId={classId}/>}
                                </Grid>
                            )}
                            {(roomId !== "" && roomId !== undefined) && (
                                <Grid item sm>
                                    {<PdfDownloadButton semester={semester} week={week} roomId={roomId}/>}
                                </Grid>
                            )}
                            {(staffId !== "" && staffId !== undefined) && (
                                <Grid item sm>
                                    {<PdfDownloadButton semester={semester} week={week} staffId={staffId}/>}
                                </Grid>
                            )}
                            {((classId === "" || classId === undefined) && (staffId === "" || staffId === undefined) && (roomId === "" || roomId === undefined)) && (
                                <Grid item sm>
                                    <DisabledDownloadButton
                                        tooltip={t("selectionBoxes.downloads.pdf") + " " + t("selectionBoxes.downloads.notAvailable")}
                                        icon={PrintIcon}
                                    />
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Box>
            </Toolbar>
        </ StyledMainAppBar>
    )
}

