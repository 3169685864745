import {IconButton, Tooltip} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setForceClient, toggleDesktop, toggleMobile} from "../../data/clientStateSlice";
import {useTranslation} from "react-i18next";
import LaptopIcon from '@mui/icons-material/Laptop';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

export const ChangeClientButton = () => {
    return <ViewStateButton/>
}

const ViewStateButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const mobile = useAppSelector(state => state.clientState.mobile);

    const handleDesktopClick = () => {
        dispatch(setForceClient(true))
        dispatch(toggleDesktop());
    }

    const handleMobileClick = () => {
        dispatch(setForceClient(true))
        dispatch(toggleMobile());
    }

    if (mobile) {
        return (
            <Tooltip title={t("selectionBoxes.clientSelection.desktopTitle")}>
                <IconButton onClick={handleDesktopClick}>
                    <LaptopIcon/>
                </IconButton>
            </Tooltip>

        )
    } else {
        return (
            <Tooltip title={t("selectionBoxes.clientSelection.mobileTitle")}>
                <IconButton onClick={handleMobileClick}>
                    <SmartphoneIcon/>
                </IconButton>
            </Tooltip>
        )
    }

}
