import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    examSearch: ""
}

export const examSearchStateSlice = createSlice({
    name: "examSearchState",
    initialState,
    reducers: {
        setExamSearchState: (state, action: PayloadAction<string>) => {
            state.examSearch = action.payload;
        }
    }
});

export const {setExamSearchState} = examSearchStateSlice.actions;

export default examSearchStateSlice.reducer;