import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TimeTable} from "../../middleware/activitiesMiddleware";

const initialState: { examData: TimeTable | undefined } = {
    examData: undefined
}

export const examDataStateSlice = createSlice({
    name: "examDataState",
    initialState,
    reducers: {
        setExamDataState: (state, action: PayloadAction<TimeTable | undefined>) => {
            state.examData = action.payload;
        }
    }
});

export const {setExamDataState} = examDataStateSlice.actions;

export default examDataStateSlice.reducer;