import {useQuery} from "@tanstack/react-query";
import {useSearchParams} from "react-router-dom";
import {callGetSemesterById, callGetSemesters, SemesterResponse} from "../api/semester";

export interface GetSemesterIdFromUrlData {
    readonly exists: boolean;
    readonly semesterId: string | undefined;
    readonly setId: (value: string) => void;
}

export const useGetSemesterIdFromUrl = (): GetSemesterIdFromUrlData => {
    const [params, setParams] = useSearchParams();
    return {
        semesterId: params.get("semester") || undefined,
        exists: params.has("semester"),
        setId: (newId) => setParams((prev) => {
            prev.set("semester", newId);
            prev.delete("class");
            prev.delete("staff");
            return prev;
        }),
    };
}

export const useGetSemesters = () => {
    const {setId, exists} = useGetSemesterIdFromUrl();
    return useQuery<SemesterResponse[]>({
        queryKey: ["semestersAll"],
        queryFn: () => callGetSemesters().then(response => {
            const data = response.data || [];
            if (!exists && data[0]?.id) {
                setId(data[0]?.id);
            }
            return Promise.resolve(data);
        })
    })
}

export const useGetSemesterById = () => {
    const {semesterId} = useGetSemesterIdFromUrl();
    return useQuery<SemesterResponse>({
        queryKey: ["semesterById", semesterId],
        queryFn: () => callGetSemesterById(semesterId!).then(response => {
            const data = response.data;
            return Promise.resolve(data);
        })
    })
}