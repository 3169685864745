import instance from "./axios";

export interface ClassesResponse {
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly department: {
        readonly id: string, //ID für den Stundenplan
        readonly name: string,
        readonly description: string,
        readonly telefon: string,
        readonly fax: string,
        readonly street: string
    }
}

export const callGetAllClasses = (semesterId: string) => instance.get<ClassesResponse[]>("api/v1/semesters/" + semesterId + "/classes");

export const callGetClassById = (classId: string, semesterId: string) => instance.get<ClassesResponse>("api/v1/semesters/" + semesterId + "/classes/" + classId);