import {IconButton, Tooltip} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import {toggleShowOnlyExamState} from "../../../data/showOnlyExamStateSlice";

import SchoolIcon from '@mui/icons-material/School';
import {activityColors} from "../../../config/style/colors";

export const ShowOnlyExamSelectButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const showOnlyExam = useAppSelector(state => state.showOnlyExamState.showOnlyExam);

    const handleClick = () => {
        dispatch(toggleShowOnlyExamState());
    }

    if (showOnlyExam) {
        return (
            <Tooltip title={t("selectionBoxes.examShowSelectionButton.active")}>
                <IconButton onClick={handleClick} sx={{color: activityColors.prüfung}}>
                    <SchoolIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Tooltip title={t("selectionBoxes.examShowSelectionButton.inactive")}>
            <IconButton onClick={handleClick}>
                <SchoolIcon/>
            </IconButton>
        </Tooltip>
    )
}
