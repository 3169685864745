import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RoomSearch} from "../../api/rooms";

const initialState: { freeRoomSearch: RoomSearch } = {
    freeRoomSearch: {
        location: "%",
        zone: "%",
        capacity: "0",
        weekDayList: [],
        weekList: [],
        startTimeframe: "",
        endTimeframe: "",
    }
}

export const freeRoomSearchStateSlice = createSlice({
    name: "freeRoomSearchState",
    initialState,
    reducers: {
        setFreeRoomSearchStateLocation: (state, action: PayloadAction<string>) => {
            state.freeRoomSearch.location = action.payload;
        },
        setFreeRoomSearchStateZone: (state, action: PayloadAction<string>) => {
            state.freeRoomSearch.zone = action.payload;
        },
        setFreeRoomSearchStateCapacity: (state, action: PayloadAction<string>) => {
            state.freeRoomSearch.capacity = action.payload;
        },
        setFreeRoomSearchStateWeekDayList: (state, action: PayloadAction<string[]>) => {
            state.freeRoomSearch.weekDayList = action.payload;
        },
        setFreeRoomSearchStateWeekList: (state, action: PayloadAction<string[]>) => {
            state.freeRoomSearch.weekList = action.payload;
        },
        setFreeRoomSearchStateStartTimeFrame: (state, action: PayloadAction<string>) => {
            state.freeRoomSearch.startTimeframe = action.payload;
        },
        setFreeRoomSearchStateEndTimeFrame: (state, action: PayloadAction<string>) => {
            state.freeRoomSearch.endTimeframe = action.payload;
        },
    }
});

export const {
    setFreeRoomSearchStateLocation,
    setFreeRoomSearchStateZone,
    setFreeRoomSearchStateCapacity,
    setFreeRoomSearchStateWeekDayList,
    setFreeRoomSearchStateWeekList,
    setFreeRoomSearchStateStartTimeFrame,
    setFreeRoomSearchStateEndTimeFrame
} = freeRoomSearchStateSlice.actions;

export default freeRoomSearchStateSlice.reducer;