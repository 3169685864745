import {AppointmentTooltip as AppointmentTooltipBase, ViewState} from '@devexpress/dx-react-scheduler';
import {
    Appointments,
    AppointmentTooltip,
    CurrentTimeIndicator,
    Scheduler,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import {Box, Grid, Paper, Typography} from "@mui/material";
import {cleanRows, createRows, filterRows, TimetableActivityParent} from "../../middleware/timeTableMiddleware";
import {CalendarEntry, mapRowsToCalendarData} from '../../middleware/mapper/calendarMapper';
import {useGetSemesterById} from '../../middleware/semesterMiddleware';
import {getCurrentYears, getDateOfISOWeek, getSelectedYearFromWeekAndYears} from '../../util/dateCalculator';
import {useAppSelector} from '../../app/hooks';
import {useTranslation} from 'react-i18next';
import {LecturerPopover} from '../table/popover/LecturerPopover';
import {RoomPopover} from '../table/popover/RoomPopover';
import {getColorForActivity} from '../../config/style/colors';
import {StaffResponse} from '../../api/timeTable';
import {RoomResponse} from '../../api/rooms';
import {ClassesResponse} from "../../api/classes";
import {ClassPopover} from "../table/popover/ClassPopover";
import {useEffect, useState} from "react";
import AppointmentProps = Appointments.AppointmentProps;

type TimeCellProps = {
    date: string,
    startTime: string,
    endTime: string,
}

type ClassCellProps = {
    classes: ClassesResponse[],
}

type LecturerCellProps = {
    lecturers: StaffResponse[],
}

type RoomCellProps = {
    rooms: RoomResponse[],
}

export const ActivitiesCalender = ({data, isRoomplan = false}) => {

    return (
        <Box>
            <CalendarInner responseData={data} isRoomplan={isRoomplan}/>
        </Box>

    )

}

const CalendarInner = (props) => {

    const currentWeek = useAppSelector(state => state.weekState).week;
    const {t} = useTranslation();
    const semester = useGetSemesterById();
    const showOnlyExams = useAppSelector(state => state.showOnlyExamState.showOnlyExam);
    const [rows, setRows] = useState<TimetableActivityParent[]>([]);

    useEffect(() => {
        setRows(cleanRows(filterRows(createRows(props.responseData), showOnlyExams)));
    }, [props.responseData, showOnlyExams]);

    if (semester.isLoading) {
        return (
            <>
                <Typography>
                    {t("common.loading")}
                </Typography>
            </>
        )
    }

    if (semester.isError) {
        return (
            <>
                <Typography>
                    {t("common.error")}
                </Typography>
            </>
        )
    }

    const years: number[] = getCurrentYears(semester.data?.description);
    const currentDate = getDateOfISOWeek(currentWeek, getSelectedYearFromWeekAndYears(currentWeek, years));
    const calendarData = mapRowsToCalendarData(rows, getCurrentYears(semester.data?.description), props.isRoomplan);

    //check if there is an activity for sunday
    const saturdayActivity = calendarData.filter(activity => activity.startDate.getDay() == 6);
    const sundayActivity = calendarData.filter(activity => activity.startDate.getDay() == 0);

    let excludedDays = [] as number[];
    if (saturdayActivity.length == 0) {
        excludedDays.push(6);
    }
    if (sundayActivity.length == 0) {
        excludedDays.push(0);
    }

    return (
        <Paper>
            <Scheduler data={calendarData} firstDayOfWeek={1} locale={"de-GR"}>
                <ViewState currentDate={currentDate}/>
                <WeekView startDayHour={8} endDayHour={22} excludedDays={excludedDays}/>
                <Appointments appointmentComponent={Appointment}/>
                <AppointmentTooltip headerComponent={HeaderContent} contentComponent={Content} showCloseButton/>
                <CurrentTimeIndicator shadePreviousCells={true} shadePreviousAppointments={true}
                                      updateInterval={60000}/>
            </Scheduler>
        </Paper>
    )

}

const Appointment = (props: AppointmentProps) => {
    const color: string = getColorForActivity(props.data.type);
    return (
        <Appointments.Appointment
            {...props}
            style={{backgroundColor: color, fontSize: "large"}}
        >
            {props.children}
        </Appointments.Appointment>
    )
}

const HeaderContent = (props: AppointmentTooltipBase.HeaderProps) => {
    const color: string = getColorForActivity(props.appointmentData!.type);
    return (
        <Paper sx={{padding: 1, backgroundColor: color}}>
            <Grid container>
                <Grid item xs={10} sx={{alignItems: "center", display: "flex", flexWrap: "wrap"}}>
                    <Typography variant="h6" sx={{color: "white", fontWeight: "bold"}}>
                        {props.appointmentData?.title}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{alignItems: "center", display: "flex"}}>
                    <props.commandButtonComponent id={"close"} onExecute={props.onHide}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{color: "white"}}>
                        {props.appointmentData?.type}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

const Content = (props) => {

    const data: CalendarEntry = props.appointmentData;
    const date: string = data.startDate.toLocaleDateString("de-DE");

    const startTimeArray: string[] = data.startDate.toLocaleTimeString("de-DE").split(":");
    startTimeArray.pop();
    const endTimeArray: string[] = data.endDate.toLocaleTimeString("de-DE").split(":");
    endTimeArray.pop();
    const startTime: string = startTimeArray.join(":");
    const endTime: string = endTimeArray.join(":");

    const activePage: number = useAppSelector(state => state.pageState.page);
    const color: string = getColorForActivity(props.appointmentData?.type);

    return (
        <Box sx={{padding: 1}}>
            <Grid container spacing={1}>
                <TimeCell date={date} startTime={startTime} endTime={endTime}/>
                {activePage !== 1 && (
                    <ClassCell classes={data.classes}/>
                )}
                {activePage !== 4 && (
                    <LecturerCell lecturers={data.lecturers}/>
                )}
                {activePage !== 3 && (
                    <RoomCell rooms={data.location}/>
                )}
            </Grid>
        </Box>
    );
}

const TimeCell = (props: TimeCellProps) => {
    const date = props.date;
    const startTime = props.startTime;
    const endTime = props.endTime;
    return (
        <>
            <Grid item xs={6}>
                <Paper sx={{padding: 1, textAlign: "center"}}>
                    <Typography>{date}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper sx={{padding: 1, textAlign: "center"}}>
                    <Typography>{startTime} - {endTime}</Typography>
                </Paper>
            </Grid>
        </>
    )
}

const ClassCell = (props: ClassCellProps) => {
    const classes = props.classes;
    if (classes.length === 0 || classes === undefined) {
        return (
            <></>
        );
    } else {
        return (
            <Grid item xs={12}>
                <Paper sx={{padding: 1}}>
                    {classes.map(classes => (
                        <ClassPopover key={classes.id} classes={classes}/>
                    ))}
                </Paper>
            </Grid>
        );
    }
}

const LecturerCell = (props: LecturerCellProps) => {
    const lecturers = props.lecturers
    if (lecturers.length === 0 || lecturers === undefined) {
        return (
            <></>
        );
    } else {
        return (
            <Grid item xs={12}>
                <Paper sx={{padding: 1}}>
                    {lecturers.map(lecturer => (
                        <LecturerPopover key={lecturer.id} lecturer={lecturer}/>
                    ))}
                </Paper>
            </Grid>
        );
    }
}

const RoomCell = (props: RoomCellProps) => {
    const rooms = props.rooms;
    if (rooms.length === 0 || rooms === undefined) {
        return (
            <></>
        );
    } else {
        return (
            <Grid item xs={12}>
                <Paper sx={{padding: 1}}>
                    {rooms.map(room => (
                        <RoomPopover key={room.id} room={room}/>
                    ))}
                </Paper>
            </Grid>
        );
    }
}