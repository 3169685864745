import {useQuery} from "@tanstack/react-query";
import {callGetWeeks} from "../api/weeks";
import {useGetSemesterIdFromUrl} from "./semesterMiddleware";

export const useGetWeeks = () => {
    const {semesterId} = useGetSemesterIdFromUrl();

    return useQuery<[string[]] | null>({
        queryKey: ["weeks", semesterId],
        queryFn: async () => {
            if (semesterId! === "") {
                return Promise.resolve(null);
            }
            const response = await callGetWeeks(semesterId!);
            return response.data;
        }
    })

}