import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setFreeRoomSearchStateLocation} from "../../../../data/room/freeRoomSearchStateSlice";
import {useEffect} from "react";

export const LocationSelection = () => {
    return (
        <>
            <LocationSelectionInner/>
        </>
    )
}

const LocationSelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);
    const dispatch = useAppDispatch();
    const locations = [
        {name: "Beide", value: "%"},
        {name: "Görlitz", value: "G %"},
        {name: "Zittau", value: "Z %"}
    ];
    const selectedLocation = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.location);
    const selectedZone = useAppSelector(state => state.freeRoomSearchState.freeRoomSearch.zone);

    useEffect(() => {
        if (selectedZone.startsWith("G")) {
            dispatch(setFreeRoomSearchStateLocation("G %"));
        }
        if (selectedZone.startsWith("Z")) {
            dispatch(setFreeRoomSearchStateLocation("Z %"));
        }
        if (selectedZone.startsWith("%")) {
            dispatch(setFreeRoomSearchStateLocation("%"));
        }
    }, [selectedZone, dispatch]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        dispatch(setFreeRoomSearchStateLocation(event.target.value));
    }

    if (mobileClient) {
        return (
            <MobileView data={locations} selectedLocation={selectedLocation} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={locations} selectedLocation={selectedLocation} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.locationSelection.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedLocation} label={t("selectionBoxes.locationSelection.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((location) =>
                            <MenuItem value={location.value} key={location.name}>
                                {location.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}

const MobileView = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="class-select-label">{t("selectionBoxes.locationSelection.mobile.title")}</InputLabel>
                <Select disabled={props.data.length === 0} labelId="class-select-label" id="class-select"
                        value={props.selectedLocation} label={t("selectionBoxes.locationSelection.mobile.title")}
                        onChange={props.handleChange}>
                    {
                        props.data.map((location) =>
                            <MenuItem value={location.value} key={location.name}>
                                {location.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}