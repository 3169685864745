import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip
} from "@mui/material";
import {useGetWeeks} from "../../../middleware/weekSelectionMiddleware";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setWeekState} from "../../../data/selector/weekStateSlice";
import {useTranslation} from "react-i18next";

import {LoadingForm} from "../common/LoadingForm";
import {ErrorForm} from "../common/ErrorForm";
import {getCurrentWeek} from "../../../util/dateCalculator";

import TodayIcon from '@mui/icons-material/Today';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {SO_SE_MAX_WEEK, SO_SE_MIN_WEEK, WI_SE_MAX_WEEK, WI_SE_MIN_WEEK} from "../../../config/config";

export const WeekSelection = () => {

    const dispatch = useAppDispatch();
    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    const {isLoading, isError, data} = useGetWeeks();
    const {t} = useTranslation();

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    const handleChange = (event: SelectChangeEvent<number>) => {
        const newWeek: number = Number(event.target.value);
        dispatch(setWeekState(newWeek));
    }


    if (mobileClient) {
        return (
            <MobileView data={data} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={data} handleChange={handleChange}/>
        )
    }

}

const DesktopView = (props) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const currentWeek = useAppSelector(state => state.weekState.week);
    const viewState = useAppSelector(state => state.viewState.view);

    const handleClick = (increase: number) => {
        const minWeek = Number(props.data[0][0]);
        const maxWeek = Number(props.data[props.data.length - 1][0]);
        const newWeek = calcNewWeek(minWeek, maxWeek, currentWeek, increase);
        dispatch(setWeekState(newWeek));
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <IconButton onClick={() => handleClick(-1)}>
                    <ArrowLeftIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth size="small">
                    <InputLabel id="week-select-label">{t("selectionBoxes.weekSelection.title")}</InputLabel>
                    <Select value={currentWeek} onChange={props.handleChange} disabled={props.data?.length === 0}
                            labelId="week-select-label" id="week-select"
                            label={t("selectionBoxes.weekSelection.title")}>
                        {!viewState && (
                            <MenuItem value={0}>{t("selectionBoxes.weekSelection.all")}</MenuItem>
                        )}
                        {
                            props.data?.map((week) =>
                                <MenuItem
                                    value={week[0]}
                                    key={week[0]}
                                    sx={getElementStyle(week[1])}
                                >
                                    <Box sx={getTextBoxStyle(week[0])}>
                                        {week[0]}
                                    </Box>
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={() => handleClick(1)}>
                    <ArrowRightIcon/>
                </IconButton>
            </Grid>
        </Grid>
    )
}


const MobileView = (props) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const currentWeek = useAppSelector(state => state.weekState.week);
    const viewState = useAppSelector(state => state.viewState.view);

    const handleClick = (increase: number) => {
        const minWeek = Number(props.data[0][0]);
        const maxWeek = Number(props.data[props.data.length - 1][0]);
        const newWeek = calcNewWeek(minWeek, maxWeek, currentWeek, increase);
        dispatch(setWeekState(newWeek));
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <IconButton onClick={() => handleClick(-1)}>
                    <ArrowLeftIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth size="small">
                    <InputLabel id="week-select-label">{t("selectionBoxes.weekSelection.title")}</InputLabel>
                    <Select value={currentWeek} onChange={props.handleChange} disabled={props.data?.length === 0}
                            labelId="week-select-label" id="week-select"
                            label={t("selectionBoxes.weekSelection.title")}>
                        {!viewState && (
                            <MenuItem value={0}>{t("selectionBoxes.weekSelection.all")}</MenuItem>
                        )}
                        {
                            props.data?.map((week) =>
                                <MenuItem value={week[0]}
                                          key={week[0]}
                                          sx={getElementStyle(week[1])}
                                >
                                    <Box sx={getTextBoxStyle(week[0])}>
                                        {week[0]}
                                    </Box>
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={() => handleClick(1)}>
                    <ArrowRightIcon/>
                </IconButton>
            </Grid>
        </Grid>
    )

}

export const CurrentWeekButton = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        const currentWeek = getCurrentWeek();
        dispatch(setWeekState(currentWeek));
    }

    return (
        <Tooltip title={t("selectionBoxes.weekSelection.current")}>
            <IconButton onClick={handleClick}>
                <TodayIcon/>
            </IconButton>
        </Tooltip>
    )

}

const calcNewWeek = (minWeek: number, maxWeek: number, currentWeek: number, increment: number): number => {
    const startWeek = currentWeek;
    if (currentWeek === 52 && increment === 1) {
        currentWeek = 1;
    } else if (currentWeek === 1 && increment === -1) {
        currentWeek = 52;
    } else {
        currentWeek += increment;
    }

    if (minWeek === SO_SE_MIN_WEEK && maxWeek === SO_SE_MAX_WEEK) {
        if (startWeek === SO_SE_MIN_WEEK && currentWeek < SO_SE_MIN_WEEK) currentWeek = SO_SE_MIN_WEEK;
        else if (startWeek === SO_SE_MAX_WEEK && currentWeek > SO_SE_MAX_WEEK) currentWeek = SO_SE_MAX_WEEK;
    } else if (minWeek === WI_SE_MIN_WEEK && maxWeek === WI_SE_MAX_WEEK) {
        if (startWeek === WI_SE_MIN_WEEK && currentWeek < WI_SE_MIN_WEEK) currentWeek = WI_SE_MIN_WEEK;
        else if (startWeek === WI_SE_MAX_WEEK && currentWeek > WI_SE_MAX_WEEK) currentWeek = WI_SE_MAX_WEEK;
    }

    return currentWeek;
}

const getTextBoxStyle = (week: number) => {
    const currentWeek = getCurrentWeek();
    if (week == currentWeek) {
        return (
            {fontWeight: "bold"}
        )
    }
    return {}
}

const getElementStyle = (state: string) => {
    if (state === "true") {
        return (
            {color: "red"}
        )
    } else {
        return (
            {}
        )
    }
}