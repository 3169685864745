import {IconButton, SvgIconTypeMap, Tooltip} from "@mui/material"
import {OverridableComponent} from "@mui/material/OverridableComponent";

type DisabledDownloadButtonProps = {
    tooltip: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
}

export const DisabledDownloadButton = (props: DisabledDownloadButtonProps) => {
    return (
        <Tooltip title={props.tooltip}>
            <IconButton disabled={true}>
                <props.icon/>
            </IconButton>
        </Tooltip>
    )
}