import {TimetableActivityParent} from "../middleware/timeTableMiddleware";

export const getActivitiesForDay = (activites: TimetableActivityParent[], day: string): TimetableActivityParent[] => {
    const array: TimetableActivityParent[] = [];
    activites.forEach(element => {
        if (element.activity.day === day) {
            array.push(element);
        }
    });
    return array;
}