import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: { authorities?: any, department?: any } = {
    authorities: undefined,
    department: undefined,
}

export const authoritiesCookieStateSlice = createSlice({
    name: "authoritiesCookieState",
    initialState,
    reducers: {
        setAuthoritiesCookieState: (state, action: PayloadAction<{ authorities?: any }>) => {
            state.authorities = action.payload.authorities;
        },
        setDepartmentCookieState: (state, action: PayloadAction<{ department?: any }>) => {
            state.department = action.payload.department;
        }
    }
});

export const {setAuthoritiesCookieState, setDepartmentCookieState} = authoritiesCookieStateSlice.actions;

export default authoritiesCookieStateSlice.reducer;