import {useState} from "react";
import {ClassesResponse} from "../../../api/classes";
import {GeneralPopover, StyledPopoverButton} from "./GeneralPopover";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

interface props {
    classes: ClassesResponse
}

export const ClassPopover = ({classes}: props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const {t} = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const displayText: string[] = [
        t("activitiesTable.popovers.general.name") + ": " + classes?.name,
        t("activitiesTable.popovers.general.description") + ": " + classes?.description,
        t("activitiesTable.popovers.general.faculty") + ": " + classes?.department?.name,
    ]

    return (
        <>
            <StyledPopoverButton size="small" onClick={handleClick}>
                <Typography variant="body2">{classes.name}</Typography>
            </StyledPopoverButton>
            <GeneralPopover data={displayText} id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}/>
        </>
    );
}