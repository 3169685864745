import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    classes: "",
    className: "",
}

export const classesStateSlice = createSlice({
    name: "classesState",
    initialState,
    reducers: {
        setClassesState: (state, action: PayloadAction<string>) => {
            state.classes = action.payload;
        },
        setClassNameState: (state, action: PayloadAction<string>) => {
            state.className = action.payload;
        }
    }
});

export const {setClassesState, setClassNameState} = classesStateSlice.actions;

export default classesStateSlice.reducer;