import _, {forEach} from "lodash";
import {nameToWeekDay, StaffResponse, weekDayToName} from "../api/timeTable";
import {Activity, TimeTable} from "./activitiesMiddleware"
import {RoomResponse} from "../api/rooms";
import {ClassesResponse} from "../api/classes";
import {arrayMoveImmutable} from "array-move";

export interface TimetableActivityParent {
    readonly id: string,
    readonly activity: TimetableActivity
}

export interface TimetableActivity {
    readonly day: string,
    readonly endTime: string,
    readonly name: string,
    readonly description: string,
    readonly classes: string,
    readonly classesInfo: ClassesResponse[],
    readonly room: string,
    readonly roomInfo: RoomResponse[],
    readonly startTime: string,
    readonly type: string,
    readonly weeks: number[],
    readonly lecturer: string,
    readonly lecturerInfo: StaffResponse[],
}

const createRowElement = (activity: Activity, weekDay: number): TimetableActivity => {
    const weeks: number[] = [...activity.weeks].sort((a, b) => a - b);
    const day: string = weekDayToName(weekDay);
    const name: string = activity.name;
    const description: string = activity.description;
    const classes: string = activity.classes;
    const classesInfo: ClassesResponse[] = activity.classesInfo;
    const type: string = activity.type;
    const startTime: string = activity.startTime;
    const endTime: string = activity.endTime;
    const room: string = activity.room;
    const roomInfo: RoomResponse[] = activity.roomInfo;
    const lecturer: string = activity.lecturer;
    const lecturerInfo: StaffResponse[] = activity.lecturerInfo;
    return {
        weeks,
        day,
        name,
        description,
        classes,
        classesInfo,
        type,
        startTime,
        endTime,
        room,
        roomInfo,
        lecturer,
        lecturerInfo
    };
}

export const createWeekRangesString = (sortedWeeks: number[]): string => {
    let weekRanges: number[][] = [];
    let currentRange: number[] = [];

    for (let i = 0; i < sortedWeeks.length; i++) {
        if (i === 0) {
            currentRange.push(sortedWeeks[i]);
        } else if (sortedWeeks[i] === sortedWeeks[i - 1] + 1) {
            currentRange.push(sortedWeeks[i]);
        } else {
            weekRanges.push(currentRange);
            currentRange = [sortedWeeks[i]];
        }
    }

    weekRanges.push(currentRange);

    // Sortiere die weekRanges aufsteigend
    weekRanges = sortWeekRanges(weekRanges);

    // Überprüfe, ob es eine Lücke zwischen den Wochen gibt und füge sie zusammen
    const mergedRanges: number[][] = [];
    let currentMergedRange = weekRanges[0];

    for (let i = 1; i < weekRanges.length; i++) {
        const currentRange = weekRanges[i];
        if (currentRange[0] === currentMergedRange[currentMergedRange.length - 1] + 1) {
            currentMergedRange.push(...currentRange);
        } else {
            mergedRanges.push(currentMergedRange);
            currentMergedRange = currentRange;
        }
    }

    mergedRanges.push(currentMergedRange);

    // Formatieren der Wochenbereiche
    const weekString = mergedRanges.map(range => {
        if (range.length === 1) {
            return range[0].toString();
        } else {
            return `${range[0]}-${range[range.length - 1]}`;
        }
    }).join(', ');
    return weekString;
}

const sortWeekRanges = (ranges: number[][]): number[][] => {
    //WiSe: 39 - 07
    //SoSe: 11 - 29
    ranges.sort((a, b) => a[0] - b[0]);
    if (ranges[0][0] <= 7) {
        for (const element of ranges) {
            if (element[0] <= 7) {
                ranges = arrayMoveImmutable(ranges, 0, ranges.length - 1);
            }
        }
    }
    return ranges;
}

export const filterRows = (rows: TimetableActivity[], showOnlyExams: boolean): TimetableActivity[] => {
    if (showOnlyExams) {
        return rows.filter((row) => {
            return row.type.toLowerCase().includes("prüfung");
        });
    }
    return rows;
}

export const createRows = (data: TimeTable | undefined | null): TimetableActivity[] => {
    return data?.days.map((day) => {
        return day.activities.map((activity) => {
            return createRowElement(activity, day.index);
        });
    }).flat() || [];
}

export const cleanRows = (rows: TimetableActivity[]): TimetableActivityParent[] => {
    const sortedRows = _.sortBy(rows, (row) => {
        return nameToWeekDay(row.day)
    }).sort((a, b) => {
        return a.startTime.localeCompare(b.startTime);
    });
    let cleanedRows: TimetableActivityParent[] = sortedRows.map((element) => {
        return {
            id: Object.values(element).join(""),
            activity: element
        }
    })
    return _.uniqBy(cleanedRows, (e) => {
        return e.id;
    });
}

export const getRowsByWeek = (week: number, rows: TimetableActivityParent[]): TimetableActivityParent[] => {
    const activites: TimetableActivityParent[] = Array(0);
    forEach(rows, (row) => {
        const weeks: number[] = row.activity.weeks;
        if (weeks.includes(week)) activites.push(row);
    })
    return activites;
}


