import {useQueryClient} from "@tanstack/react-query";
import {useGetSemesterIdFromUrl} from "../../middleware/semesterMiddleware";
import {useGetClassIdFromUrl} from "../../middleware/classesMiddleware";
import {useGetRoomIdFromUrl} from "../../middleware/roomMiddleware";
import {useGetUserCredentials} from "../../middleware/userMiddleware";
import {callGetNewToken} from "../../api/user";
import {Grid, IconButton, InputBase} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useTranslation} from "react-i18next";
import {ErrorForm} from "../selectionBox/common/ErrorForm";
import {LoadingForm} from "../selectionBox/common/LoadingForm";
import {useEffect, useState} from "react";
import {HSZG_MAIN_COLOR} from "../../config/style/colors";
import {useGetStaffIdFromUrl} from "../../middleware/staffMiddleware";
import {useAppSelector} from "../../app/hooks";

type AboLinkButtonProps = {
    readonly setOpen: (value: boolean) => void;
}

export const AboLinkButton = (props: AboLinkButtonProps) => {
    const queryClient = useQueryClient();

    const {semesterId = ""} = useGetSemesterIdFromUrl();
    const {classId = ""} = useGetClassIdFromUrl();
    const className = useAppSelector(state => state.classesState.className);
    const roomName = useAppSelector(state => state.roomState.roomName);
    const {roomId = ""} = useGetRoomIdFromUrl();
    const {staffId = ""} = useGetStaffIdFromUrl();
    const userCredentials = useGetUserCredentials();
    const {t} = useTranslation();

    const [aboLink, setAboLink] = useState("");
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    useEffect(() => {
        if (userCredentials.isSuccess) {
            if (!(classId === "" || classId === undefined)) {
                setAboLink(window.origin + "/api/v1/ics-export/semesters/@latest/classes/@" + className)
            } else if (!(roomId === "" || roomId === undefined)) {
                setAboLink(window.origin + "/api/v1/ics-export/semesters/@latest/rooms/@" + roomName)
            } else if (userCredentials.data[0] !== undefined && !(staffId === "" || staffId === undefined)) {
                setAboLink(window.origin + "/api/v1/ics-export/semesters/@latest/staff/abo/" + userCredentials.data[0])
            } else {
                setAboLink("")
            }
        }
    }, [semesterId, classId, roomId, userCredentials.isLoading])

    const handleRefreshToken = async () => {
        await callGetNewToken();
        await queryClient.invalidateQueries(["userCredentials"]);
    }

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(aboLink);
        props.setOpen(false);
    }

    const togglePlaceholder = () => {
        setShowPlaceholder(!showPlaceholder);
    };

    if (userCredentials.isLoading) {
        return (
            <LoadingForm title={t("common.loading")}/>
        )
    }

    // @ts-ignore
    if (userCredentials.isError && userCredentials.error.response.status !== 403) {
        return (
            <ErrorForm title={t("common.error")}/>
        )
    }

    return (
        <Grid
            container
            spacing={0.5}
            alignItems={"center"}
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                border: '1px solid',
                borderColor: HSZG_MAIN_COLOR,
                borderRadius: '4px',
            }}
        >
            {
                aboLink === "" ?
                    <Grid item style={{flexGrow: 1, overflow: 'hidden'}}>
                        <InputBase
                            value={"Kein Abolink verfügbar"}
                            readOnly
                            fullWidth
                            style={{
                                overflow: 'auto',
                                whiteSpace: 'nowrap',
                            }}
                        />
                    </Grid>
                    :
                    <>
                        <Grid item style={{flexGrow: 1, overflow: 'hidden'}}>
                            <InputBase
                                value={showPlaceholder ? t("selectionBoxes.downloads.ics.abo") : aboLink}
                                readOnly
                                fullWidth
                                style={{
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={togglePlaceholder}>
                                {showPlaceholder ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                            </IconButton>
                            <IconButton size="small" onClick={copyLinkToClipboard}>
                                <ContentCopyIcon/>
                            </IconButton>
                            {(
                                    JSON.stringify(userCredentials.data) !== JSON.stringify([]) &&
                                    !JSON.stringify(userCredentials.data).includes("<!DOCTYPE html>")
                                ) &&
                                <IconButton size="small" onClick={handleRefreshToken}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Grid>
                    </>
            }
        </Grid>
    )
}