import "./style.css";

import {Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {createWeekRangesString, TimetableActivityParent} from "../../middleware/timeTableMiddleware";
import {getColorForActivity, HSZG_HOVER_GRAY, HSZG_LIGHT_GRAY, HSZG_MAIN_ACTIVE_COLOR} from "../../config/style/colors";
import {useTranslation} from "react-i18next";
import {RoomPopover} from "./popover/RoomPopover";
import {LecturerPopover} from "./popover/LecturerPopover";
import {ClassPopover} from "./popover/ClassPopover";
import {useState} from "react";
import {getTimeString} from "../../util/getTimeString";
import {getActivitiesForDay} from "../../util/getActivitiesForDay";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from "@emotion/styled";

type DesktopViewProps = {
    rows: TimetableActivityParent[],
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
}

type ActivityRowProps = {
    row: TimetableActivityParent,
    id: string,
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
}

type ActivityTableCellProps = {
    row: TimetableActivityParent,
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
}

type DayRowProps = {
    day: string,
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
    rows: TimetableActivityParent[],
}

export const ActivityTableDesktop = (props: DesktopViewProps) => {

    const {t} = useTranslation();
    const rows: TimetableActivityParent[] = props.rows;
    const mondayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Montag");
    const tuesdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Dienstag");
    const wednesdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Mittwoch");
    const thursdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Donnerstag");
    const fridayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Freitag");
    const saturdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Samstag");
    const sundayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Sonntag");

    return (
        <TableContainer id="activityTable" component={Paper}>
            <Table size="small" aria-label="activities table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("activitiesTable.weekTitle")}</TableCell>
                        <TableCell>{t("activitiesTable.time.title")}</TableCell>
                        <TableCell>{t("activitiesTable.activityTitle")}</TableCell>
                        {props.showClassCell && <TableCell>{t("activitiesTable.classTitle")}</TableCell>}
                        {props.showLecturerCell && <TableCell>{t("activitiesTable.lecturerTitle")}</TableCell>}
                        {props.showRoomCell && <TableCell>{t("activitiesTable.roomTitle")}</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <DayRow day="Montag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                            showLecturerCell={props.showLecturerCell} rows={mondayRows}/>

                    <DayRow day="Dienstag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                            showLecturerCell={props.showLecturerCell} rows={tuesdayRows}/>

                    <DayRow day="Mittwoch" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                            showLecturerCell={props.showLecturerCell} rows={wednesdayRows}/>

                    <DayRow day="Donnerstag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                            showLecturerCell={props.showLecturerCell} rows={thursdayRows}/>

                    <DayRow day="Freitag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                            showLecturerCell={props.showLecturerCell} rows={fridayRows}/>

                    {saturdayRows.length > 0 &&
                        <DayRow day="Samstag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                                showLecturerCell={props.showLecturerCell} rows={saturdayRows}/>
                    }

                    {sundayRows.length > 0 &&
                        <DayRow day="Sonntag" showRoomCell={props.showRoomCell} showClassCell={props.showClassCell}
                                showLecturerCell={props.showLecturerCell} rows={sundayRows}/>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const DayRow = (props: DayRowProps) => {
    let columns: number = 3;
    const [isExpanded, setIsExpanded] = useState(true);

    if (props.showClassCell) {
        columns++;
    }

    if (props.showLecturerCell) {
        columns++;
    }

    if (props.showRoomCell) {
        columns++;
    }

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <StyledDayRowContainer onClick={toggleExpansion}>
                <StyledDayRowText colSpan={columns}>
                    <Box sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {props.day}
                        {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </Box>
                </StyledDayRowText>
            </StyledDayRowContainer>
            {isExpanded &&
                props.rows.map((row, index) => (
                    <ActivityRow row={row} key={row.id} id={row.id} showClassCell={props.showClassCell}
                                 showLecturerCell={props.showLecturerCell} showRoomCell={props.showRoomCell}/>
                ))
            }
        </>
    )
}

const StyledDayRowContainer = styled(TableRow)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        backgroundColor: HSZG_LIGHT_GRAY,
        "&:hover": {
            backgroundColor: HSZG_HOVER_GRAY,
            cursor: "pointer",
        }
    },
    //@ts-ignore
    [theme.getColorSchemeSelector("dark")]: {
        backgroundColor: "#161616",
        "&:hover": {
            backgroundColor: "#272727",
            cursor: "pointer",
        }
    },
}));

const StyledDayRowText = styled(TableCell)(({theme}) => ({
    //@ts-ignore
    [theme.getColorSchemeSelector("light")]: {
        color: "black",
    },
    //@ts-ignore
    [theme.getColorSchemeSelector("dark")]: {
        color: HSZG_MAIN_ACTIVE_COLOR
    },
    fontWeight: "bold"
}));

const ActivityRow = (props: ActivityRowProps) => {
    const color: string = getColorForActivity(props.row.activity.type);
    return (
        <TableRow color={color} key={props.id}>
            <ActivityTableCells row={props.row}
                                showClassCell={props.showClassCell}
                                showLecturerCell={props.showLecturerCell}
                                showRoomCell={props.showRoomCell}
            />
        </TableRow>
    );
}

const ActivityTableCells = (props: ActivityTableCellProps) => {

    const {t} = useTranslation();
    const color: string = getColorForActivity(props.row.activity.type);
    const weekString: string = createWeekRangesString(props.row.activity.weeks);
    return (
        <>
            <TableCell align="left">
                {weekString}
            </TableCell>
            <TableCell
                align="left">{getTimeString(props.row.activity.startTime)} {t("activitiesTable.time.aClock")} {t("activitiesTable.time.seperator")} {getTimeString(props.row.activity.endTime)} {t("activitiesTable.time.aClock")}
            </TableCell>
            <TableCell align="left">
                <Chip size="small" label={props.row.activity.type} sx={{backgroundColor: color, color: "white"}}/>
                <br/>
                {props.row.activity.name + " " + props.row.activity.description}
            </TableCell>
            {props.showClassCell &&
                <TableCell align="left">
                    {props.row.activity.classesInfo.map(classes => (
                        <ClassPopover key={classes.id} classes={classes}/>
                    ))}
                </TableCell>
            }
            {props.showLecturerCell &&
                <TableCell align="left">
                    {props.row.activity.lecturerInfo.map(lecturer => (
                        <LecturerPopover key={lecturer.id} lecturer={lecturer}/>
                    ))}
                </TableCell>
            }
            {props.showRoomCell &&
                <TableCell align="left">
                    {props.row.activity.roomInfo.map(room => (
                        <RoomPopover key={room.id} room={room}/>
                    ))}
                </TableCell>
            }
        </>
    )
}