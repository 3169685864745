import {forEach} from "lodash";
import {nameToWeekDay} from "../api/timeTable";
import {arrayMoveImmutable} from "array-move";

export const getCurrentWeekDay = (): number => {
    const currentDate = new Date();
    return currentDate.getDay() == 0 ? 6 : currentDate.getDay() - 1;
}

/**
 *
 * @returns The current week based on the current date
 */
export const getCurrentWeek = (): number => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = dateDiffInDays(currentDate, startDate) + 1;

    const dayOfWeek = currentDate.getDay();
    const adjustedDays = dayOfWeek === 0 ? days + 1 : days;

    const weekNumber = Math.ceil(adjustedDays / 7);
    return weekNumber;
}

export const getSelectedYearFromWeekAndYears = (week: number, years: number[]): number => {

    if (years.length === 1) return years[0];

    let result: number = 0;

    if (week > 35) {
        result = years[0];
    } else {
        result = years.pop()!;
    }

    return result;
}

/**
 * This function takes a week and a year and return the first date of that week aka the Monday
 * @param week The week of the date
 * @param year The year of the date
 * @returns The date of the first day of that week
 */
export const getDateOfISOWeek = (week, year): Date => {
    const simple: Date = new Date(year, 0, 1 + (week - 1) * 7);
    const dow: number = simple.getDay();
    let ISOweekStart: Date = simple;

    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }

    return ISOweekStart;
}

/**
 * This function takes a start and end week and fills an array of numbers with the weeks between these two values including those weeks
 * @param startWeek The beginning of the time period
 * @param endWeek The end of the time period
 * @returns An array of numbers containing all weeks between start and end week and those two weeks included
 */
export const weeksToWeekArray = (startWeek: number, endWeek: number): number[] => {
    let array: number[] = Array(0);
    if (startWeek < endWeek) {
        for (let i = 0; i <= endWeek - startWeek; i++) {
            array.push(startWeek + i);
        }
        return array;
    } else {
        for (let i = 0; i <= 52 - startWeek; i++) {
            array.push(startWeek + i);
        }
        for (let i = 0; i < endWeek; i++) {
            array.push(i + 1);
        }
        return array;
    }
}

/**
 * This function takes a string describing a number of weeks and turns that string into a number array containing these weeks
 * @param weeks A string formated either "40 - 5" or "40"
 * @returns An array of numbers containing all weeks contained in the string
 */
export const getWeeksFromString = (weeks: string): number[] => {
    const weeksRegex = /^\d+\s-\s\d+$/; //40 - 5
    const singleWeekRegex = /^\d+/; //40

    let weeksToCalc: number[] = Array(0);
    if (weeksRegex.test(weeks)) {
        const splitString = weeks.split(" ");
        const startWeek: number = Number(splitString[0]);
        const endWeek: number = Number(splitString[splitString.length - 1]);
        weeksToCalc = weeksToWeekArray(startWeek, endWeek);
    } else if (singleWeekRegex.test(weeks)) {
        const weekNumber: number = Number(weeks);
        weeksToCalc.push(weekNumber);
    }

    return weeksToCalc;
}

/**
 * This function uses the text in the semester selection box to create a list of current selected years
 * @param text This text is the description of the semester which is provided by the backend
 * @returns A list of years which are currently selected.
 */
export const getCurrentYears = (text: string): number[] => {
    const result: number[] = Array(0);
    const singleYearRegex = /^\d{4}$/;
    const multiYearRegex = /\d+\/\d+/i;

    const yearText: string = text.split(" ").pop()!;
    if (singleYearRegex.test(yearText)) {
        result.push(Number(yearText));
    } else if (multiYearRegex.test(yearText)) {
        const yearSplit: string[] = yearText.split("/");
        result.push(Number(yearSplit[0]));
        result.push(Number("20" + yearSplit[1]));
    }
    return result;
}

/**
 *
 * @param weeks A string of weeks either in the format "40 - 5" or "45". These numbers are only examples
 * @param weekDay The day of the week the activity happens. The name is written
 * @param years A list of years, that are currently selected. Should be provided by getCurrentYears
 * @returns Produces a list of dates. These dates show every instance of an activity happening.
 */
export const calendarWeekStringToDateList = (weeks: number[], weekDay: string, years: number[]): Date[] => {

    const weeksToCalc: number[] = sortWeeksByYear(weeks);

    const result: Date[] = Array(0);
    let preWeek: number = 0;
    let currentYear: number = years[0];
    forEach(weeksToCalc, (week) => {

        if (preWeek > week || (weeksToCalc.length === 1 && week <= 7)) {
            currentYear = years[years.length - 1];
        }

        const weekDate: Date = getDateOfISOWeek(week, currentYear);
        result.push(addDays(weekDate, nameToWeekDay(weekDay)));
        preWeek = week;
    });

    return result;

}

const sortWeeksByYear = (weeks: number[]): number[] => {
    if (weeks[0] <= 7) {
        for (const element of weeks) {
            if (element <= 7) {
                weeks = arrayMoveImmutable(weeks, 0, weeks.length - 1);
            }
        }
    }
    return weeks;
}

/**
 * This function returns the difference between two dates als days
 * @param a The first date of a time period
 * @param b The second date of a time period
 * @returns The difference between two dates in days
 */
const dateDiffInDays = (a: Date, b: Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc1 - utc2) / _MS_PER_DAY);
}

/**
 * This function adds a number of days to a provided date
 * @param date The start date of the calculation
 * @param days The number of days to add to the date
 * @returns The new date calculated by adding a number of days to the starting date
 */
const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 * Takes a date and returns the week of the year wich containes the provided date
 * @param input The date to be used
 * @returns The week that contains the provided date
 */
export const getWeekFromDate = (input: Date): number => {
    let date = new Date(input.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    let week = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week.getTime()) / 86400000 - 3 + (week.getDay() + 6) % 7) / 7);
}