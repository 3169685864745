import {Autocomplete, AutocompleteChangeReason, OutlinedTextFieldProps, TextField} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {useGetClassesOfSemester} from "../../../middleware/classesMiddleware";
import {useTranslation} from "react-i18next";
import {LoadingForm} from "../common/LoadingForm";
import {ErrorForm} from "../common/ErrorForm";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setClassNameState} from "../../../data/selector/classesStateSlice";
import {SyntheticEvent, useEffect, useMemo, useState} from "react";
import {ClassesResponse} from "../../../api/classes";

type ClassSelectionViewProps = {
    data: ClassesResponse[],
    selectedClass: ClassesResponse | null,
    handleChange: (event: SyntheticEvent<Element, Event>, value: ClassesResponse | null, reason: AutocompleteChangeReason) => void
}

export const ClassesSelection = () => {
    return (
        <>
            <ClassSelectionInner/>
        </>
    )
}

const ClassSelectionInner = () => {

    const mobileClient: boolean = useAppSelector(state => state.clientState.mobile);

    const [params, setParams] = useSearchParams();
    const {isLoading, isError, data = []} = useGetClassesOfSemester();
    const dispatch = useAppDispatch();
    const [selectedClassId, setSelectedClassId] = useState<string>("");
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedClassId(getSelectedClass(params, data));
        dispatch(setClassNameState(data.find((classes) => classes.id === selectedClassId)?.name ?? ""));
    }, [params, data, selectedClassId, dispatch]);

    const selectedClass = useMemo(() =>
            data.find((classes) => classes.id === selectedClassId) || null,
        [data, selectedClassId],
    );

    if (isLoading) {
        return (
            <LoadingForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    if (isError) {
        return (
            <ErrorForm title={t("selectionBoxes.weekSelection.title")}/>
        )
    }

    const handleChange = (event: SyntheticEvent<Element, Event>, value: ClassesResponse | null, reason: AutocompleteChangeReason) => {
        setSelectedClassId(value?.id || "");
        setParams((prev) => {
            prev.set("class", value?.id || "");
            return prev
        });
    }

    if (mobileClient) {
        return (
            <MobileView data={data} selectedClass={selectedClass} handleChange={handleChange}/>
        )
    } else {
        return (
            <DesktopView data={data} selectedClass={selectedClass} handleChange={handleChange}/>
        )
    }
}

const DesktopView = ({data, selectedClass, handleChange}: ClassSelectionViewProps) => {
    const {t} = useTranslation();

    return (
        <Autocomplete
            id={"class-select"}
            size={"small"}
            autoHighlight={true}
            autoSelect={true}
            options={data.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option: ClassesResponse) => option.name + ": " + option.description}
            onChange={handleChange}
            value={selectedClass}
            renderInput={(params: unknown) => (
                <TextField
                    {...params as OutlinedTextFieldProps}
                    label={t("selectionBoxes.classesSelection.title")}
                />
            )}
        />
    )
}

const MobileView = ({data, selectedClass, handleChange}: ClassSelectionViewProps) => {
    const {t} = useTranslation();

    return (
        <Autocomplete
            id={"class-select"}
            size={"small"}
            autoHighlight={true}
            autoSelect={true}
            options={data.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option: ClassesResponse) => option.name}
            onChange={handleChange}
            value={selectedClass}
            renderInput={(params: unknown) => (
                <TextField
                    {...params as OutlinedTextFieldProps}
                    label={t("selectionBoxes.classesSelection.mobile.title")}
                />
            )}
        />
    )
}

const getSelectedClass = (params, data): string => {
    if (params.has("class")) {
        return params.get("class")!!;
    }
    return data[0]?.department?.id ?? "";
}