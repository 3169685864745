import {Box, Divider, Grid} from "@mui/material";
import {SemesterSelection} from "./selection/SemesterSelection";
import {WeekSelection} from "./selection/WeekSelection";
import {ViewSelection} from "./selection/ViewSelection";
import {CurrentWeekButton} from "./buttons/CurrentWeekButton";
import {HSZG_MAIN_COLOR} from "../../config/style/colors";
import {ExamSearch} from "./examSearch/ExamSearch";
import {ExamSelection} from "./selection/ExamSelection";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {TimeTable} from "../../middleware/activitiesMiddleware";
import {setExamLocationState} from "../../data/exam/examLocationStateSlice";
import {setExamTypeState} from "../../data/exam/examTypeStateSlice";
import {setExamClassesState} from "../../data/exam/examClassesStateSlice";
import {DateWeekPickerButton} from "../datePicker/DateWeekPickerButton";
import {useTranslation} from "react-i18next";

type ButtonRowProps = {
    showCalendarSelectButton: boolean,
    showCalendarViewButton: boolean,
}

const extractAllClasses = (data: TimeTable) => {
    let examClasses = ["Alle"];

    for (let dayKey in data.days) {
        for (let activityKey in data.days[dayKey].activities) {
            for (let examClassKey in data.days[dayKey].activities[activityKey].classesInfo) {
                let examClass = data.days[dayKey].activities[activityKey].classesInfo[examClassKey];
                if (!examClasses.includes(examClass.name)) {
                    examClasses = [...examClasses, examClass.name];
                }
            }
        }
    }

    return examClasses.sort();
}

const extractAnyType = (data: TimeTable) => {
    let types = ["Alle"];

    for (let dayKey in data.days) {
        for (let activityKey in data.days[dayKey].activities) {
            let activity = data.days[dayKey].activities[activityKey];
            if (!types.includes(activity.type)) {
                types = [...types, activity.type];
            }
        }
    }

    return types;
}

export const ExamSelectionBox = () => {

    const {t} = useTranslation();
    const mobile = useAppSelector(state => state.clientState.mobile);

    const [examDataThere, setExamDataThere] = useState(true);
    const [examTypes, setExamTypes] = useState<string[]>([]);
    const [examClasses, setExamClasses] = useState<string[]>([]);
    const examLocations = [t("selectionBoxes.examSelection.defaultLocations.all"), t("selectionBoxes.examSelection.defaultLocations.g"), t("selectionBoxes.examSelection.defaultLocations.z"), t("selectionBoxes.examSelection.defaultLocations.else")];
    const examData = useAppSelector(state => state.examDataState.examData);

    useEffect(() => {
        if (examData && typeof examData === 'object' && 'days' in examData) {
            setExamDataThere(false);
            setExamTypes(extractAnyType(examData));
            setExamClasses(extractAllClasses(examData));
        }
    }, [examData]);

    if (mobile) {
        return (
            <Box sx={{marginTop: 2}}>
                <Grid container spacing={2} sx={{width: "100%", px: 5}}>
                    <Grid item xs={12}>
                        <SemesterSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <ExamSearch text={t("selectionBoxes.examSelection.examName")}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ExamSelection text={t("selectionBoxes.examSelection.class")} available={examDataThere}
                                       data={examClasses} setter={setExamClassesState}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ExamSelection text={t("selectionBoxes.examSelection.type")}
                                       available={examDataThere}
                                       data={examTypes}
                                       setter={setExamTypeState}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ExamSelection text={t("selectionBoxes.examSelection.location")}
                                       available={examDataThere}
                                       data={examLocations}
                                       setter={setExamLocationState}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <WeekSelection/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonRow showCalendarSelectButton={true} showCalendarViewButton={true}/>
                    </Grid>
                </Grid>
                <Divider sx={{mt: 3, backgroundColor: HSZG_MAIN_COLOR}}/>
            </Box>
        )
    }

    return (
        <Box sx={{marginTop: 3}}>
            <Grid container spacing={2} sx={{width: "100%", px: 5}}>
                <Grid item xs={12} md={6} lg={2}>
                    <SemesterSelection/>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <ExamSearch text={t("selectionBoxes.examSelection.examName")}/>
                </Grid>
                <Grid item xs={3} md={2} lg={1}>
                    <ExamSelection text={t("selectionBoxes.examSelection.class")} available={examDataThere}
                                   data={examClasses} setter={setExamClassesState}/>
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <ExamSelection text={t("selectionBoxes.examSelection.type")}
                                   available={examDataThere}
                                   data={examTypes}
                                   setter={setExamTypeState}
                    />
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <ExamSelection text={t("selectionBoxes.examSelection.location")}
                                   available={examDataThere}
                                   data={examLocations}
                                   setter={setExamLocationState}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <WeekSelection/>
                </Grid>
                <Grid item xs={6} md={2} lg={1}>
                    <ButtonRow showCalendarSelectButton={true} showCalendarViewButton={true}/>
                </Grid>
            </Grid>
            <Divider sx={{mt: 3, backgroundColor: HSZG_MAIN_COLOR}}/>
        </Box>
    )

}

const ButtonRow = (props: ButtonRowProps) => {
    return (
        <Grid container>
            {props.showCalendarSelectButton && (
                <Grid item xs>
                    <DateWeekPickerButton/>
                </Grid>
            )}
            <Grid item xs>
                <CurrentWeekButton/>
            </Grid>
            {props.showCalendarViewButton && (
                <Grid item xs>
                    <ViewSelection/>
                </Grid>
            )}
        </Grid>
    )
}