import {Box, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getColorForActivity, HSZG_MAIN_ACTIVE_COLOR} from "../../config/style/colors";
import {createWeekRangesString, TimetableActivityParent} from "../../middleware/timeTableMiddleware";
import {LecturerPopover} from "./popover/LecturerPopover";
import {RoomPopover} from "./popover/RoomPopover";
import {ClassPopover} from "./popover/ClassPopover";
import {useState} from "react";
import {getTimeString} from "../../util/getTimeString";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type MobileViewProps = {
    rows: TimetableActivityParent[],
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
}

type MobileActivitiesTableCellProps = {
    data: TimetableActivityParent,
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
}

type DayCardProps = {
    day: string,
    showClassCell: boolean,
    showLecturerCell: boolean,
    showRoomCell: boolean,
    rows: TimetableActivityParent[],
}

export const ActivityTableMobile = (props: MobileViewProps) => {

    const rows: TimetableActivityParent[] = props.rows;
    const mondayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Montag");
    const tuesdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Dienstag");
    const wednesdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Mittwoch");
    const thursdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Donnerstag");
    const fridayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Freitag");
    const saturdayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Samstag");
    const sundayRows: TimetableActivityParent[] = getActivitiesForDay(rows, "Sonntag");

    return (
        <>
            <List sx={{paddingTop: "0"}}>
                <DayCard
                    day="Montag"
                    showRoomCell={props.showRoomCell}
                    showLecturerCell={props.showLecturerCell}
                    showClassCell={props.showClassCell}
                    rows={mondayRows}
                />

                <DayCard
                    day="Dienstag"
                    showRoomCell={props.showRoomCell}
                    showLecturerCell={props.showLecturerCell}
                    showClassCell={props.showClassCell}
                    rows={tuesdayRows}
                />

                <DayCard
                    day="Mittwoch"
                    showRoomCell={props.showRoomCell}
                    showLecturerCell={props.showLecturerCell}
                    showClassCell={props.showClassCell}
                    rows={wednesdayRows}
                />

                <DayCard
                    day="Donnerstag"
                    showRoomCell={props.showRoomCell}
                    showLecturerCell={props.showLecturerCell}
                    showClassCell={props.showClassCell}
                    rows={thursdayRows}
                />

                <DayCard
                    day="Freitag"
                    showRoomCell={props.showRoomCell}
                    showLecturerCell={props.showLecturerCell}
                    showClassCell={props.showClassCell}
                    rows={fridayRows}
                />

                {saturdayRows.length !== 0 &&
                    <DayCard
                        day="Samstag"
                        showRoomCell={props.showRoomCell}
                        showLecturerCell={props.showLecturerCell}
                        showClassCell={props.showClassCell}
                        rows={saturdayRows}
                    />
                }

                {sundayRows.length !== 0 &&
                    <DayCard
                        day="Sonntag"
                        showRoomCell={props.showRoomCell}
                        showLecturerCell={props.showLecturerCell}
                        showClassCell={props.showClassCell}
                        rows={sundayRows}
                    />
                }
            </List>
        </>
    )

}

const DayCard = (props: DayCardProps) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <Paper elevation={1}
                   onClick={toggleExpansion}
                   sx={{
                       width: "100%",
                       marginBottom: 0.5,
                       paddingY: 1,
                       paddingX: 1,
                       backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
                       display: "flex",
                       justifyContent: "space-between",
                   }}
            >
                <Box></Box>
                <Typography sx={{fontWeight: "bold"}}>{props.day}</Typography>
                {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </Paper>

            {isExpanded &&
                props.rows.map((row) => (
                    <ListItem key={row.id} disablePadding>
                        <MobileActivitiesTableCell showClassCell={props.showClassCell}
                                                   showLecturerCell={props.showLecturerCell}
                                                   showRoomCell={props.showRoomCell}
                                                   data={row}
                        />
                    </ListItem>
                ))
            }
        </>
    )
}

const MobileActivitiesTableCell = (props: MobileActivitiesTableCellProps) => {
    const {t} = useTranslation();
    const data: TimetableActivityParent = props.data;
    const elevation: number = 2;
    const color: string = getColorForActivity(data.activity.type);
    const weekString: string = createWeekRangesString(data.activity.weeks);
    return (
        <Paper elevation={1} sx={{width: "100%", marginBottom: 0.5, paddingY: 1, paddingX: 2}}>
            <Grid container spacing={0.5}>
                <Grid item xs={5} sx={{textAlign: "center"}}>
                    <Paper elevation={elevation} sx={{
                        paddingY: 0.5,
                        paddingX: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly"
                    }}>
                        <Typography variant="body2"
                                    sx={{fontWeight: "bold"}}>
                            {t("activitiesTable.weekTitle")}: {weekString}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={7} sx={{textAlign: "center"}}>
                    <Paper elevation={elevation} sx={{
                        paddingY: 0.5,
                        paddingX: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly"
                    }}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {getTimeString(data.activity.startTime)} {t("activitiesTable.time.aClock")} {t("activitiesTable.time.seperator")} {getTimeString(data.activity.endTime)} {t("activitiesTable.time.aClock")}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sx={{textAlign: "left"}}>
                    <Paper elevation={elevation} sx={{paddingY: 0.5, paddingX: 1, backgroundColor: color}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {data.activity.type}
                        </Typography>
                        <Typography
                            variant="subtitle2">{data.activity.name + " " + data.activity.description}
                        </Typography>
                    </Paper>
                </Grid>

                {(props.showClassCell && data.activity.classesInfo.length !== 0) && (
                    <Grid item xs={12} sx={{textAlign: "left"}}>
                        <Paper elevation={elevation} sx={{paddingY: 0.5, paddingX: 1}}>
                            {data.activity.classesInfo.map(classes => (
                                <ClassPopover key={classes.id} classes={classes}/>
                            ))}
                        </Paper>
                    </Grid>
                )}

                {(props.showLecturerCell && data.activity.lecturerInfo.length !== 0) && (
                    <Grid item xs={12} sx={{textAlign: "left"}}>
                        <Paper elevation={elevation} sx={{paddingY: 0.5, paddingX: 1}}>
                            {data.activity.lecturerInfo.map(lecturer => (
                                <LecturerPopover key={lecturer.id} lecturer={lecturer}/>
                            ))}
                        </Paper>
                    </Grid>
                )}

                {(props.showRoomCell && data.activity.roomInfo.length !== 0) && (
                    <Grid item xs={12} sx={{textAlign: "left"}}>
                        <Paper elevation={elevation} sx={{paddingY: 0.5, paddingX: 1}}>
                            {data.activity.roomInfo.map(room => (
                                <RoomPopover key={room.id} room={room}/>
                            ))}
                        </Paper>
                    </Grid>
                )}

            </Grid>
        </Paper>
    )
}

const getActivitiesForDay = (activites: TimetableActivityParent[], day: string): TimetableActivityParent[] => {
    const array: TimetableActivityParent[] = [];
    activites.forEach(element => {
        if (element.activity.day === day) {
            array.push(element);
        }
    });
    return array;
}