export const searchTimes = [
    {name: "08:00 Uhr", value: "2000-01-01 08:00:00.000"},
    {name: "08:15 Uhr", value: "2000-01-01 08:15:00.000"},
    {name: "08:30 Uhr", value: "2000-01-01 08:30:00.000"},
    {name: "08:45 Uhr", value: "2000-01-01 08:45:00.000"},
    {name: "09:00 Uhr", value: "2000-01-01 09:00:00.000"},
    {name: "09:15 Uhr", value: "2000-01-01 09:15:00.000"},
    {name: "09:30 Uhr", value: "2000-01-01 09:30:00.000"},
    {name: "09:45 Uhr", value: "2000-01-01 09:45:00.000"},
    {name: "10:00 Uhr", value: "2000-01-01 10:00:00.000"},
    {name: "10:15 Uhr", value: "2000-01-01 10:15:00.000"},
    {name: "10:30 Uhr", value: "2000-01-01 10:30:00.000"},
    {name: "10:45 Uhr", value: "2000-01-01 10:45:00.000"},
    {name: "11:00 Uhr", value: "2000-01-01 11:00:00.000"},
    {name: "11:15 Uhr", value: "2000-01-01 11:15:00.000"},
    {name: "11:30 Uhr", value: "2000-01-01 11:30:00.000"},
    {name: "11:45 Uhr", value: "2000-01-01 11:45:00.000"},
    {name: "12:00 Uhr", value: "2000-01-01 12:00:00.000"},
    {name: "12:15 Uhr", value: "2000-01-01 12:15:00.000"},
    {name: "12:30 Uhr", value: "2000-01-01 12:30:00.000"},
    {name: "12:45 Uhr", value: "2000-01-01 12:45:00.000"},
    {name: "13:00 Uhr", value: "2000-01-01 13:00:00.000"},
    {name: "13:15 Uhr", value: "2000-01-01 13:15:00.000"},
    {name: "13:30 Uhr", value: "2000-01-01 13:30:00.000"},
    {name: "13:45 Uhr", value: "2000-01-01 13:45:00.000"},
    {name: "14:00 Uhr", value: "2000-01-01 14:00:00.000"},
    {name: "14:15 Uhr", value: "2000-01-01 14:15:00.000"},
    {name: "14:30 Uhr", value: "2000-01-01 14:30:00.000"},
    {name: "14:45 Uhr", value: "2000-01-01 14:45:00.000"},
    {name: "15:00 Uhr", value: "2000-01-01 15:00:00.000"},
    {name: "15:15 Uhr", value: "2000-01-01 15:15:00.000"},
    {name: "15:30 Uhr", value: "2000-01-01 15:30:00.000"},
    {name: "15:45 Uhr", value: "2000-01-01 15:45:00.000"},
    {name: "16:00 Uhr", value: "2000-01-01 16:00:00.000"},
    {name: "16:15 Uhr", value: "2000-01-01 16:15:00.000"},
    {name: "16:30 Uhr", value: "2000-01-01 16:30:00.000"},
    {name: "16:45 Uhr", value: "2000-01-01 16:45:00.000"},
    {name: "17:00 Uhr", value: "2000-01-01 17:00:00.000"},
    {name: "17:15 Uhr", value: "2000-01-01 17:15:00.000"},
    {name: "17:30 Uhr", value: "2000-01-01 17:30:00.000"},
    {name: "17:45 Uhr", value: "2000-01-01 17:45:00.000"},
    {name: "18:00 Uhr", value: "2000-01-01 18:00:00.000"},
    {name: "18:15 Uhr", value: "2000-01-01 18:15:00.000"},
    {name: "18:30 Uhr", value: "2000-01-01 18:30:00.000"},
    {name: "18:45 Uhr", value: "2000-01-01 18:45:00.000"},
    {name: "19:00 Uhr", value: "2000-01-01 19:00:00.000"},
    {name: "19:15 Uhr", value: "2000-01-01 19:15:00.000"},
    {name: "19:30 Uhr", value: "2000-01-01 19:30:00.000"},
    {name: "19:45 Uhr", value: "2000-01-01 19:45:00.000"},
    {name: "20:00 Uhr", value: "2000-01-01 20:00:00.000"},
    {name: "20:15 Uhr", value: "2000-01-01 20:15:00.000"},
    {name: "20:30 Uhr", value: "2000-01-01 20:30:00.000"},
    {name: "20:45 Uhr", value: "2000-01-01 20:45:00.000"},
    {name: "21:00 Uhr", value: "2000-01-01 21:00:00.000"},
    {name: "21:15 Uhr", value: "2000-01-01 21:15:00.000"},
    {name: "21:30 Uhr", value: "2000-01-01 21:30:00.000"},
    {name: "21:45 Uhr", value: "2000-01-01 21:45:00.000"},
    {name: "22:00 Uhr", value: "2000-01-01 22:00:00.000"}
];