import './App.css'
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Timetable} from "./pages/Timetable";
import {Lecturerplan} from "./pages/Lecturerplan";
import {Planner} from "./pages/Planner";
import {Roomplan} from "./pages/Roomplan";
import {Examplan} from "./pages/Examplan";
import {ClientSelector} from './components/clients/ClientSelector';
import {useAppDispatch} from './app/hooks';
import {toggleDesktop, toggleMobile} from './data/clientStateSlice';
import {useEffect} from 'react';
import {CssBaseline} from '@mui/material';
import {Experimental_CssVarsProvider as CssVarsProvider} from '@mui/material/styles';
import {ECampus} from './pages/ECampus';
import {mainTheme} from './config/style/colors';
import {throttle} from "lodash";
import {saveState} from "./browser-storage";
import {store} from "./store";
import {PdfPreview} from "./pages/PdfPreview";

if (!document.documentURI.includes("@")) {
    store.subscribe(throttle(() => {
        saveState(store.getState());
    }, 1000));
}

function App() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1100) {
                dispatch(toggleMobile());
            } else {
                dispatch(toggleDesktop());
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        return function cleanupListener() {
            window.removeEventListener("resize", handleResize);
        }
    }, [dispatch]);

    return (
        <CssVarsProvider theme={mainTheme}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route path="/" element={<ClientSelector/>}>
                        <Route index element={<Home/>}/>
                        <Route path={"/timetable"} element={<Timetable/>}/>
                        <Route path={"/examplan"} element={<Examplan/>}/>
                        <Route path={"/roomplan"} element={<Roomplan/>}/>
                        <Route path={"/lecturerplan"} element={<Lecturerplan/>}/>
                        <Route path={"/planner"} element={<Planner/>}/>
                        <Route path={"/ecampus"} element={<ECampus/>}/>
                        <Route path={"/preview"} element={<PdfPreview/>}/>
                    </Route>
                </Routes>
            </div>
        </CssVarsProvider>
    );
}

export default App;
