import React, {ReactNode} from "react";
import {Fab, Paper, styled} from "@mui/material";

import {HSZG_MAIN_ACTIVE_COLOR, HSZG_MAIN_ACTIVE_DARK_COLOR} from "../../../config/style/colors";

import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {WeekPicker} from "../../datePicker/WeekPicker";

type MobileSelectionBoxProps = {
    content: ReactNode
}

export const MobileSelectionBox = (props: MobileSelectionBoxProps) => {

    const [show, setShow] = React.useState(true);

    return (
        <>
            {show ? (
                <StyledFab onClick={() => setShow(false)}>
                    <KeyboardArrowDownIcon/>
                </StyledFab>
            ) : (
                <StyledFab onClick={() => setShow(true)}>
                    <KeyboardControlKeyIcon/>
                </StyledFab>
            )}
            {show && (
                <Paper sx={{position: "absolute", bottom: 55, left: 0, width: 1, zIndex: 1300, px: 2}}>
                    <WeekPicker/>
                    {props.content}
                </Paper>
            )}
        </>
    )
}

const StyledFab = styled(Fab)({
    backgroundColor: HSZG_MAIN_ACTIVE_COLOR,
    position: 'absolute',
    zIndex: 1400,
    top: -12,
    left: 0,
    right: 0,
    margin: '0 auto',
    "&:hover": {
        color: HSZG_MAIN_ACTIVE_COLOR,
        backgroundColor: HSZG_MAIN_ACTIVE_DARK_COLOR,
    }
});