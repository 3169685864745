import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    view: false
}

export const viewStateSlice = createSlice({
    name: "viewState",
    initialState,
    reducers: {
        toggleCalendar: (state) => {
            state.view = true;
        },
        toggleList: (state) => {
            state.view = false;
        },
    },
})

export const {toggleCalendar, toggleList} = viewStateSlice.actions;

export default viewStateSlice.reducer;